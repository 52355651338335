.rs-youtube-video-background__video-wrapper {
  @extend %responsive-video;
  position: absolute;
  left: 0;
  width: 100vw;
  height: 33vw;
}

.rs-youtube-video-background__video-positioning-wrapper {
  position: relative;
  width: 100vw;
  height: 33vw;
  overflow: hidden;
  padding-top: 0;
}

.rs-youtube-video-background__video-spacer {
  width: 100vw;
  height: 33vw;
  margin-bottom: 25px;
}

.layout-swap {
  position: static;
}

body {
  position: relative;
}

body.front { // Clearing out all the pos:rel up to the body to allow right align.
  .contextual-links-region {
    position: static;
  }
}

.rs-youtube-video-background__image {
  position: absolute;
  z-index: 1;
  top: 0;
  width: 100%;

  img {
    max-width: 100% !important;
    width: 100% !important;
  }
}

.rs-youtube-video-background__video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 100vw !important;
  height: 300% !important; //33.33vw doesn't work...
}

.rs-youtube-video-background__booking,
.rs-youtube-video-background__tagline {
  z-index: 2;
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
}

.rs-youtube-video-background__booking {
  @include button($color-brand-red);
  text-transform: uppercase;
  max-width: 450px;
  margin: 0 auto;
  bottom: 0;
  border: 2px solid transparent;
  font-size: 1.15rem;
  letter-spacing: 1px;
  font-weight: 500;

  &:hover {
    color: $white !important;
    border: 2px solid $white;
    background-color: $color-brand-green;
  }

  a {
    font-size: $font-sm-x;
    color: currentColor;
    text-decoration: none;

    &:before {
      @extend %icon-left;
      content: '\e911';
      font-family: $font-icon;
      font-size: 150%;
      vertical-align: sub;
    }
  }

  @include media-sm {
    @include button--lg-x;
    bottom: 40px;
  }
}

.rs-youtube-video-background__tagline {
  color: $white;
  //-webkit-text-stroke: 1px dimgray;
  //font-weight: $font-weight-bold;
  line-height: 1;

  @include media-sm {
    bottom: 100px;
  }

  @include media-mid {
    font-size: 280%;
  }
}

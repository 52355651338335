// Page: Services
//
// Services page styles
//
// Markup: services.twig
//
// Style guide: rosept.services

body.page-node-80 footer {
  margin-top: 0;
}

.section-treatments {
	.field-type-text-with-summary {
		font-family: $font-family-1;
		font-size: $font-lg-x;
	}

	h1 {
		margin: 0;
	}
}

.region-services {
  margin: $spacing 0 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  text-align: center;

  @include media-mid {
    text-align: left;
    margin: $spacing-4 0 0;
  }
}

.rs-services__medical_wrapper {
	margin: 0 0 $spacing-half;
}

.rs-services__medical_title_wrapper {
	background-color: $color-brand-red--mid;
	padding: 2.5px $spacing;
	width: 200px;

	a {
		color: $white;
		text-decoration: none;
	}

	h3 {
		margin: 0;
	}

	&:hover,
	&.rs-services__hover {
		background-color: $color-brand-red--dark;
	}
}

.rs-services__medical_list_wrapper {
  font-size: 100%;
	font-family: $font-family-1;
	background-color: $color-brand-red--light;
	width: 185px;
  padding: $spacing $spacing-2;
	position: relative;
  left: 15px;
	transition: none;

	a {
		text-decoration: none;
		color: inherit;
    padding: 5px 0;
    display: block;

		&:hover {
			color: $color-brand-red--dark;
		}
	}
}

#block-views-medical-conditions-block,
#block-rs-blocks-body,
#block-views-treatments-expertise-block {
	.block__title {
		text-transform: uppercase;
    color: $color-text-gray;
    white-space: nowrap;
	}
}

#block-views-medical-conditions-block {
  flex: 1 1 30%;
  margin-bottom: $spacing-2;

  .block__title {

    @include media-mid {
      padding-left: $spacing;
    }
  }

	@include media-mid {
		text-align: right;
		width: 30%;
	}

  .view-medical-conditions {
    display: inline-block;
  }

	.view-content {
		@include media-mid {
			float: right;
		}
	}
}

#block-rs-blocks-body {
  flex: 1 1 35%;
  display: none;

	@include media-mid {
		display: block;
	}
}

#block-views-treatments-expertise-block {
  flex: 1 1 35%;
}

.rs-services__body-wrapper {
	> div {
		margin: 0 auto;
	}
}

.view-treatments-expertise {
  display: inline-block;
	a {
		color: $color-steel-black;
		background-color: $color-athens-gray;
		text-decoration: none;
		font-family: $font-family-1;
		font-size: 1rem;
		line-height: 1.75rem;
		margin: 0 0 $spacing-half;
		padding: 2.5px $spacing;
		display: block;
		max-width: 260px;

		&:hover {
			background-color: $color-steel-black;
      color: $white;
		}
	}
}

#block-views-services-videos-block {
  clear: both;
  background-color: $color-light-salmon;
  margin-left: calc(50% - 50vw);
  width: 100vw;
  padding: $spacing-2 0;
  margin-top: $spacing-3;

  .services-videos-block__inner-wrapper {
    margin: auto;
    max-width: 100vw;
    padding: 0 2.5rem;

    @include media-lg {
      max-width: $site-max-width;
      padding: 0;
    }
  }

  .block__title {
  	font-size: 2.1rem;
  	margin: 0;
    color: $color-brand-red;
  }

  .view-header {
    font-family: $font-family-1;
    color: $color-text-gray;
    font-size: 1.1rem;
	}
}

.view-services-videos {
	.views-field-title {
    display: none;
	}

	.view-content {
		@include media-sm {
			@include row;
		}
	}

	.views-row {
		@include media-sm {
			@include gutter;
			width: 50%;
			float: left;

			&:nth-of-type(2n + 1) {
				clear: both;
			}
		}

		@include media-mid {
			width: 33%;

			&:nth-of-type(2n + 1) {
				clear: none;
			}

			&:nth-of-type(3n + 1) {
				clear: both;
			}
		}

		@include media-lg {
			width: 20%;

			&:nth-of-type(3n + 1) {
				clear: none;
			}

			&:nth-of-type(5n + 1) {
				clear: both;
			}
		}
	}
}

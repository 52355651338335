// Page: Blog Listing
//
// Blog Listing styles
//
// Markup: blog-listing.twig
//
// Style guide: rosept.blog-listing

// Blog-listing: Grid

.page-blog main {
  @include media-mid {
    @include col(66%);
    padding-left: 0;
  }
}

.rs-blog-list__region {
  @include media-mid {
    @include col(33%, $float: right);
    margin: 96px 0 0;
    padding-right: 0;
  }
}

// Blog-listing: Featured post

.rs-blog--featured {
  position: relative;
  min-height: 120px;

  img {
    vertical-align: middle;
  }
}

.view-mode-featured {
  border-bottom: 2px $color-brand-red--dark solid;
  margin-bottom: $spacing-3;
  padding-bottom: $spacing-3;

  .field-type-text-with-summary {
    font-size: $font-lg;
  }
}

.rs-feature--title {
  margin: 2rem 0 0 0;
  font-size: 2rem;
  color: $color-brand-red;
  line-height: 1.2;
  font-weight: 500;

  a {
    color: inherit;
    text-decoration: none;
  }
}

// Blog-listing: Posts

.rs-teaser--title {
  font-size: 1.45rem;
  line-height: 1.3;
  color: $color-brand-red;
  margin: 0;
  font-weight: 500;

  a {
    color: inherit;
    text-decoration: none;
  }
}

.page-blog {
  .view-id-blog .view-filters {
    display: none;
  }

  .views-row:not(.rs-blog__featured-row) .node {
    @include media-mid {
      @include row($flex: true);
    }

    .group-left {
      @include media-mid {
        @include col;
        flex: 1;
      }
    }

    .group-right {
      @include media-mid {
        @include col;
        flex: 1;
      }
    }
  }

  .node {
    margin: 0 0 ($spacing * 2.5);

    .more-link {
      font-weight: 300;
      font-family: $font-family-1;
      text-decoration: none;
      padding: 0;
      display: block;
      text-align: left;

      &:after {
        content: '>';
        padding: 0 0 0 $spacing-half;
      }
    }

    .field-type-text-with-summary {
      font-family: $font-family-2;
      display: inline-block;
    }
  }
}

// Blog-listing: Pagination

.pager {
  font-family: $font-family-1;
  font-size: $font-lg;
  line-height: 1.2;
  text-transform: uppercase;
  text-align: left;

  li {
    display: inline-block;
    height: 3rem;
  }

  .pager-ellipsis,
  .pager-current,
  .pager-last,
  .pager-first {
    display: none;
  }

  li:not(.pager-current) {
    cursor: pointer;
    color: $white;
    position: relative;
    background-color: $color-steel-black;

    a {
      color: $color-steel-black;;
      &:after {
        content: '';
        background: url(../images/right-arrow.svg) no-repeat center;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        height: 1.5rem;
        width: 1.5rem;
      }
    }


    &.pager-previous {
      margin-right: $spacing-half;
      a:after {
        background-image: url(../images/left-arrow.svg);

      }
    }

    .pager-next a:after {
      background-image: url(../images/right-arrow.svg);
    }

    &:hover {
      background-color: $color-brand-red;
    }
  }

  li a {
    color: inherit;
    text-decoration: none;
  }
}

.pager-current {
  background-color: $color-brand-red;
  color: $white;
}

.pager__current-item, .pager-current {
  font-weight: $font-weight-normal;
}

.pager__item, .pager__current-item, .pager-current, .pager-item, .pager-first, .pager-previous, .pager-next, .pager-last, .pager-ellipsis {
  padding: 0;
  margin-right: -5px; // to remove inline block spacing
}

.pager-current, ul.pager li a {
  display: inline-block;
  padding: $spacing ($spacing * 1.5);
}

// Blog-listing: Categories

#block-views-blog-categories-block {
  background-color: $color-light-salmon;
  padding: ($spacing * 1.5);
  margin: $spacing-2 0 0;

  .view-content {
    font-family: $font-family-1;
    font-size: $font-lg;
    padding: 0 0 0 $spacing-half;

    a {
      font-weight: $font-weight-bold;
      text-decoration: none;
    }
  }

  .views-row {
    padding: 0 0 $spacing-half;
  }

  .pager {
    font-size: $font-sm-x;
  }

  .pager-current, li a {
    padding: $spacing-half $spacing;
  }
}

// Blog-listing: Testimonial

#block-views-testimonial-block {
  color: $white;
  background: #dd3a31 url(../images/rose-light.svg) no-repeat -20px -20px;
  padding: 2.2rem;
  margin: $spacing-2 0 0;

  .views-field-title {
    font-family: $font-family-1;
    text-transform: uppercase;
    font-size: 1rem;
  }

  .views-label-body, .field-content {
    position: relative;
  }

  .field-content {
    z-index: 2;

    p {
      margin: 0 0 $spacing-half;
      text-align: left;
    }

    a {
      color: black;
    }
  }

  .views-field-body {
    font-size: 1.4rem;
    hyphens: auto;
    text-align: justify;
    position: relative;
  }
}

// Blog-listing: Search

#block-search-form {
  margin: 0 0 1.2rem;
}

#search-block-form {
  .form-item-search-block-form, #edit-actions {
    display: inline-block;
  }

  .form-item-search-block-form {
    width: calc(100% - 35px); //width of search button
  }

  #edit-actions {
    width: 35px;
    margin-left: -5px; // to override inline block whitespace

    .form-submit {
      width: 35px;
      vertical-align: middle;
      position: relative;
      left: 2px;
      top: -1px;
      padding: 4px;
      background-color: $color-light-salmon;
      height: 2rem;
      margin-top: 2px;

      &:hover {
        background-color: $color-brand-red;
      }

      &:focus {
        outline: 0;
      }
    }
  }

  input[type='text'].form-text {
    padding: .22em;
    vertical-align: middle;
    height: 2rem;
  }

  input[type='submit']#edit-submit {
    @include button;
    @include button--sm;
    padding: .3em;
  }

}

#block-views-videos-block-2 { // Featured Video Block
  .cboxElement {
    display: block;
  }

  img {
    width: 100%;
  }
}

// Blog-listing: Videos

.block__title {
  font-size: 1.2rem;
  text-transform: uppercase;
  line-height: 1.4;
  margin-bottom: 1.2rem;
}

.hp__top-links-video {
  color: $color-brand-red;
  font-family: $font-family-1;
  font-size: $font-lg;
  font-weight: $font-weight-bold;
  text-decoration: none;
}

// Page: Videos
//
// Videos styles
//
// Markup: videos.twig
//
// Style guide: rosept.videos

.view-id-videos {

  .node-teaser {
    margin-bottom: ($spacing * 2.5);

    @include media-mid {
      @include row($flex: true);
    }

    .group-left {
      @include media-mid {
        @include col;
        flex: 1;
      }
    }

    .group-right {
      @include media-mid {
        @include col;
        flex: 3;
      }
    }
  }

  .pager-last, .pager-first {
    display: none;
  }

}

// ends view-id-videos

// Colorbox Videos
.colorbox-load, %colorbox-load {
  display: inline-block;
  position: relative;

  img {
    vertical-align: middle;
  }

  &:after {
    @extend %dead-center;
    content: '\f04b';
    font-family: $font-icon;
    font-size: 20px;
    color: $color-brand-red;
    background-color: rgba($white, .8);
    border-radius: 50%;
    padding: 9px 15px;
    transition: all .2s ease-in;
  }

  &:hover:after {
    color: $black;
    background-color: rgba($white, .6);
  }
}

#cboxClose,
#colorbox {
  transition: none;
}

.rs-little-play {
  position: relative;
}

.rs-little-play__inner {
  a {
    right: -2px;
    left: auto;
    transform: translate(-50%, -50%);
    display: block;
    position: absolute;
    top: 50%;

    &:after {
      padding: 5px 10px;
      font-size: 13px;
      color: #fff;
      background-color: #dd3a31;
    }
  }

}

// Header
//
// Header Styling
//
// Markup: header-module.twig
//
// Style guide: rosept.header-module

.header {
  font-family: $font-family-1;
  padding: 0;
  margin-left: calc(50% - 50vw);
  width: 100vw;
  background-color: $color-steel-black;

  a {
    text-decoration: none;
  }

  @include media-mid {
    //@include row($gutter: $spacing-2);
  }
}

.rs-header__wrapper-inner {
  max-width: $site-max-width;
  margin: auto;
  display: block;
  padding: $spacing-3 $spacing $spacing-half $spacing;

  @extend %clearfix;

  @include media-mid-lg {
    padding: $spacing-2 $spacing;
  }

  @include media-xl {
    padding: $spacing-2 0;
  }
}

.header-wrap {
  @include media-mid {
    @include row;
  }
}

.header__logo-and-name {
  display: inline-block;
  position: relative;
  z-index: 1;

}

.header__region {
  margin: $spacing-half 0;
  padding-right: 0;

  @include media-mid {
    padding-top: 1.5rem;
    float: right;
    display: inline-block;
    text-align: right;
    margin: 0;
    padding-right: 0;
  }

  @include media-mid-lg {
    padding-top: 0;
  }
}

.header__logo {
  display: inline-block;
  vertical-align: middle;

  img {
    max-width: 65px !important;

    @include media-mid {
      max-width: 70px !important;
    }
  }
}

.header__name-and-slogan {
  height: 71px;
  display: inline-block;
  vertical-align: middle;
  width: 218px;

  @include media-mid {
    width: 230px;
  }
}

.header__site-name {
  display: none;
}

.header__site-link {
  @extend %logo__site-link;
}

// Hide skip link on menu

.visually-hidden--focusable#main-menu {
  display: none;
}

.rs-header-contact__booking {
  display: inline-block;

  a {
    color: $color-brand-red;
    font-weight: $font-weight-bold;
    margin: 0 0 $spacing;
    text-transform: uppercase;
    padding: .5rem;
    border: 2px solid transparent;

    &:hover {
      color: $white;
      border: 2px solid $white;
      background-color: $color-brand-red--dark;
    }
  }
}

.rs-header-contact__phone {
  display: inline-block;
  margin: 0 $spacing 0 0;

  a {
    color: inherit;
  }
}

.rs-header-contact__social-wrapper {
  display: inline-block;
  vertical-align: middle;
  margin-top: $spacing;

  @include media-mid {
    margin-top: 0;
  }
}

.rs-header-contact__social {
  color: $white;
  font-size: 140%;
  margin-left: $spacing-half;

  &:before {
    font-family: $font-icon;
  }

  &-fb:before {
    content: '\e907';
  }

  &-twitter { // adjust for fb icon spacing
    margin-left: 3px;
  }

  &-twitter:before {
    content: '\f099';
  }

  &-yt:before {
    content: '\ea9d';
  }

  &-instagram:before {
    content: '\ea92';
  }
}

.layout-swap__top {
  position: absolute;
  top: 0;
  width: 100vw;
  left: 0;
  margin: 0;
  padding: 0;
  height: 1px;
}

//  Main Menu.

.region-navigation {
  position: relative;

  #block-system-main-menu {
    position: static;
  }
}

#block-system-main-menu {
  clear: left;

  .block__title {
    display: none;
  }

  .responsive-menus {
    font-family: $font-family-1;
  }

  .footer__logo-and-name {
    display: none !important;
  }

  .menu__item.first {
    display: none;
  }

  .responsive-menus.responsified {
    position: fixed;
    top: 0;
    left: 0;
    //background-color: transparent;
    z-index: 9999;
    opacity: .95;
    height: 27px;
    width: 100px;
  }

  .responsive-menus.responsified ul {
    margin-bottom: 1rem;
  }

  .responsive-menus.responsified li {
    display: block;

    &.first {
      display: none;
    }
  }

  .responsive-menus.responsified span.toggler { // Closed
    width: 100px;
    border-radius: 0;
    background: $color-steel-black;
    float: left;
    color: $white;
    opacity: .95;
  }

  .responsive-menus.responsified.responsive-toggled span.toggler { // Open
    width: 190px;
    float: none;

    &:after {
      content: 'x';
      margin-left: 3rem;
    }
  }

  .responsive-menus.responsified .responsive-menus-simple {
    width: 190px;
    display: block;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear;
  }

  .responsive-menus.responsified.responsive-toggled .responsive-menus-simple {
    visibility: visible;
    opacity: .95;
  }

  .responsive-menus.responsified span.toggler,
  .responsive-menus.responsified .responsive-menus-simple {
    background: $color-steel-black;
    box-shadow: none;
    color: $white;
    border: 0;
    padding-top: $spacing-half;
  }


  ul {
    white-space: nowrap;
    padding: 0;
    left: $spacing;

    @include media-mid-lg {
      margin: 0;
      padding: 0;
      position: absolute;
      text-align: right;
      top: 73px;
      right: $spacing;
    }

    @include media-lg {
    }

    @include media-xl {
      right: calc(calc(100vw - #{$site-max-width} ) / 2);
    }

    li {
      list-style: none;
      display: inline;
      float: none;

      a {
        font-size: $font-lg-x;
        font-weight: 700;
        margin: 0 0 0 15px;
        text-decoration: none;
        text-transform: uppercase;
        border-color: $color-steel-black;
        border-bottom: 3px solid transparent;
        padding-bottom: 5px;
        display: inline-block;

        &,
        &:visited {
          color: $white;
        }

        &:hover,
        &:active,
        &.is-active-trail,
        &.active-trail,
        &.active {
          border-bottom: 3px solid $color-brand-red;
          padding-bottom: 5px;
        }

      }

      &.first a {
        margin-left: 0;
      }

    }
  }
}

.view-store .view-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  article {
    flex: 1 1 305px;
    margin: .5rem;
    background: $gray-almost-white;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    align-content: stretch;

  }

  .field-name-field-image .field-item {
    display: flex;

    img {
      flex: 0 1 100%;
    }
  }

  .rs-product__title {
    font-size: 1.25rem;
    line-height: 1.65rem;
    font-weight: 500;
    margin: 1rem 0;
  }

  .rs-product__body {
    flex: 1 0;

    .field-items {
      text-align: left;
    }
  }

  .rs-product__content-wrapper {
    flex: 1;
    padding: 0 .75rem;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .rs-product__cart {
    margin-right: .5rem
  }

  .links.inline {
    margin: 1rem 0;
    padding: 0;
    font-weight: bold;

    li {
      text-align: center;
      display: block;
      text-transform: uppercase;
    }
  }
}


.rs-product__out-of-stock {
  .node-readmore {
    font-size: 2rem;
    font-weight: bold;
    opacity: 0;
  }
  
  &:hover {
    .field-name-field-image,
    .rs-product__body,
    .rs-product__title{
      opacity: 0.3;
    }

    .node-readmore {
      opacity: 1;
    }
  }
}


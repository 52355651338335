/*! typey | GPLv2 License | https://github.com/jptaranto/typey */
@import url("//brick.a.ssl.fastly.net/Roboto:300,400,500,700,900,300i,400i,500i,700i,900i");
@import url("https://brick.a.ssl.fastly.net/Raleway:400,700/Lora:400,700i");
* {
  box-sizing: border-box;
}

html {
  font-size: 100%;
  line-height: 1.5em;
  font-family: "Roboto", "Franklin Gothic Medium", Tahoma, Arial, sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  min-height: 100%;
}

@media print {
  html {
    font-size: 12pt;
  }
}

body {
  margin: 0;
  padding: 0;
  color: #1f2131;
  background-color: #fff;
  line-height: 1.4;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
progress,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

progress {
  vertical-align: baseline;
}

template,
[hidden] {
  display: none;
}

a {
  color: #0072b9;
  -webkit-text-decoration-skip: objects;
}

/*:visited {
  color: color(link-visited);
}*/
a:active {
  color: #c00;
}

a:active:not(.button) {
  background-color: transparent;
}

a:active,
a:hover {
  outline-width: 0;
}

@media print {
  :link,
  :visited {
    text-decoration: underline;
  }
  a[href]::after {
    content: " (" attr(href) ")";
    font-weight: normal;
    font-size: 16px;
    text-decoration: none;
  }
  a[href^='javascript:']::after,
  a[href^='#']::after {
    content: '';
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto", "Franklin Gothic Medium", Tahoma, Arial, sans-serif;
  font-weight: normal;
}

h1 {
  font-size: 2.625rem;
  line-height: 3.75rem;
  margin-top: 3rem;
  margin-bottom: 3rem;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 2px;
  color: #dd3a31;
}

h2 {
  font-size: 1.5rem;
  line-height: 2.25rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  font-weight: 500;
}

h3 {
  font-size: 1.25rem;
  line-height: 2.25rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

h4 {
  font-size: 1rem;
  line-height: 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

h5 {
  font-size: 0.875rem;
  line-height: 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

h6 {
  font-size: 0.625rem;
  line-height: 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

abbr[title] {
  border-bottom: 0;
  text-decoration: underline;
  text-decoration: underline dotted;
}

@media print {
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
}

b,
strong {
  font-weight: inherit;
}

strong,
b {
  font-weight: bolder;
}

pre,
code,
kbd,
samp,
var {
  font-family: "Roboto", "Franklin Gothic Medium", Tahoma, Arial, sans-serif;
  font-size: 1em;
}

dfn {
  font-style: italic;
}

mark {
  background-color: #fd0;
  color: #000;
}

small {
  font-size: 0.875rem;
}

sub,
sup {
  font-size: 0.625rem;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

.rs-titlecasing {
  text-transform: uppercase;
}

.rs-bold_italic {
  font-weight: 700;
  font-style: oblique;
}

.divider,
hr {
  margin: 1.5rem 0;
  border: 0;
  border-top: 1px solid #cccccc;
}

.divider > :first-child, hr > :first-child {
  margin-top: 1.5rem;
}

blockquote {
  margin: 1.5rem 2rem;
}

dl,
menu,
ol,
ul {
  margin: 1.5rem 0;
}

ol ol,
ol ul,
ul ol,
ul ul {
  margin: 0;
}

dd {
  margin: 0 0 0 32px;
}

[dir="rtl"] dd {
  margin: 0 32px 0 0;
}

menu,
ol,
ul {
  padding: 0 0 0 32px;
}

[dir="rtl"] menu, [dir="rtl"]
ol, [dir="rtl"]
ul {
  padding: 0 32px 0 0;
}

figure {
  margin: 1.5rem 0;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

p,
pre {
  margin: 1.5rem 0;
}

img {
  border-style: none;
}

img,
svg {
  max-width: 100%;
  height: auto;
}

svg:not(:root) {
  overflow: hidden;
}

button,
input,
optgroup,
select,
textarea {
  box-sizing: border-box;
  max-width: 100%;
  font: inherit;
  margin: 0;
}

button {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

input {
  overflow: visible;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type='search']::-webkit-search-cancel-button, [type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-input-placeholder {
  color: inherit;
  opacity: .54;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}


button,
html [type='button'],
[type='reset'],
[type='submit'] {
  -moz-appearance: button;
  -webkit-appearance: button;
}

.button,
button,
[type='button'],
[type='reset'],
[type='submit'] {
  display: inline-block;
  padding: 2px 6px;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  color: #000;
}

.button::-moz-focus-inner,
button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

.button:-moz-focusring,
button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: 1px dotted ButtonText;
}

.button:hover, .button:focus, .button:active,
button:hover,
button:focus,
button:active,
[type='button']:hover,
[type='button']:focus,
[type='button']:active,
[type='reset']:hover,
[type='reset']:focus,
[type='reset']:active,
[type='submit']:hover,
[type='submit']:focus,
[type='submit']:active {
  text-decoration: none;
  color: #000;
}

[disabled].button,
button[disabled],
[disabled][type='button'],
[disabled][type='reset'],
[disabled][type='submit'] {
  cursor: default;
  color: #999999;
}

[disabled].button:hover,
button[disabled]:hover,
[disabled][type='button']:hover,
[disabled][type='reset']:hover,
[disabled][type='submit']:hover, [disabled].button:focus,
button[disabled]:focus,
[disabled][type='button']:focus,
[disabled][type='reset']:focus,
[disabled][type='submit']:focus, [disabled].button:active,
button[disabled]:active,
[disabled][type='button']:active,
[disabled][type='reset']:active,
[disabled][type='submit']:active {
  color: #999999;
}

[type='checkbox'],
[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}

fieldset {
  padding: 0.525rem 0.9375rem 0.975rem;
  border: 1px solid #cccccc;
  margin: 0 2px;
}

legend {
  box-sizing: border-box;
  display: table;
  max-width: 100%;
  white-space: normal;
  color: inherit;
  margin-left: -5px;
  padding: 0 5px;
}

label {
  display: block;
  font-weight: bold;
}

optgroup {
  font-weight: bold;
}

textarea {
  overflow: auto;
}

table {
  margin: 1.5rem 0;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

td,
th {
  padding: 0;
}

.layout-3col {
  margin-left: -20px;
  margin-right: -20px;
  padding-left: 0;
  padding-right: 0;
}

.layout-3col:before {
  content: "";
  display: table;
}

.layout-3col:after {
  content: "";
  display: table;
  clear: both;
}

.layout-3col__full, .layout-3col__left-content, .layout-3col__right-content, .layout-3col__left-sidebar, .layout-3col__right-sidebar, .layout-3col__first-left-sidebar, .layout-3col__second-left-sidebar, .layout-3col__col-1, .layout-3col__col-2, .layout-3col__col-3, .layout-3col__col-4, .layout-3col__col-x {
  clear: both;
  padding-left: 20px;
  padding-right: 20px;
  float: left;
  width: 100%;
  margin-left: 0%;
  margin-right: -100%;
}

[dir="rtl"] .layout-3col__full, [dir="rtl"] .layout-3col__left-content, [dir="rtl"] .layout-3col__right-content, [dir="rtl"] .layout-3col__left-sidebar, [dir="rtl"] .layout-3col__right-sidebar, [dir="rtl"] .layout-3col__first-left-sidebar, [dir="rtl"] .layout-3col__second-left-sidebar, [dir="rtl"] .layout-3col__col-1, [dir="rtl"] .layout-3col__col-2, [dir="rtl"] .layout-3col__col-3, [dir="rtl"] .layout-3col__col-4, [dir="rtl"] .layout-3col__col-x {
  float: right;
  margin-right: 0%;
  margin-left: -100%;
}

@media (min-width: 777px) {
  .layout-3col {
    margin-left: -12px;
    margin-right: -13px;
    padding-left: 0;
    padding-right: 0;
  }
  .layout-3col:before {
    content: "";
    display: table;
  }
  .layout-3col:after {
    content: "";
    display: table;
    clear: both;
  }
  [dir="rtl"] .layout-3col {
    margin-left: -13px;
    margin-right: -12px;
  }
  .layout-3col__full, .layout-3col__left-content, .layout-3col__right-content, .layout-3col__left-sidebar, .layout-3col__right-sidebar {
    float: left;
    width: 100%;
    margin-left: 0%;
    margin-right: -100%;
    padding-left: 12px;
    padding-right: 13px;
  }
  [dir="rtl"] .layout-3col__full, [dir="rtl"] .layout-3col__left-content, [dir="rtl"] .layout-3col__right-content, [dir="rtl"] .layout-3col__left-sidebar, [dir="rtl"] .layout-3col__right-sidebar {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  [dir="rtl"] .layout-3col__full, [dir="rtl"] .layout-3col__left-content, [dir="rtl"] .layout-3col__right-content, [dir="rtl"] .layout-3col__left-sidebar, [dir="rtl"] .layout-3col__right-sidebar {
    padding-left: 13px;
    padding-right: 12px;
  }
  .layout-3col__first-left-sidebar, .layout-3col__col-1, .layout-3col__col-3, .layout-3col__col-x:nth-child(2n + 1) {
    float: left;
    width: 50%;
    margin-left: 0%;
    margin-right: -100%;
    padding-left: 12px;
    padding-right: 13px;
  }
  [dir="rtl"] .layout-3col__first-left-sidebar, [dir="rtl"] .layout-3col__col-1, [dir="rtl"] .layout-3col__col-3, [dir="rtl"] .layout-3col__col-x:nth-child(2n + 1) {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  [dir="rtl"] .layout-3col__first-left-sidebar, [dir="rtl"] .layout-3col__col-1, [dir="rtl"] .layout-3col__col-3, [dir="rtl"] .layout-3col__col-x:nth-child(2n + 1) {
    padding-left: 13px;
    padding-right: 12px;
  }
  .layout-3col__second-left-sidebar, .layout-3col__col-2, .layout-3col__col-4, .layout-3col__col-x:nth-child(2n) {
    clear: none;
    float: left;
    width: 50%;
    margin-left: 50%;
    margin-right: -100%;
    padding-left: 12px;
    padding-right: 13px;
  }
  [dir="rtl"] .layout-3col__second-left-sidebar, [dir="rtl"] .layout-3col__col-2, [dir="rtl"] .layout-3col__col-4, [dir="rtl"] .layout-3col__col-x:nth-child(2n) {
    float: right;
    margin-right: 50%;
    margin-left: -100%;
  }
  [dir="rtl"] .layout-3col__second-left-sidebar, [dir="rtl"] .layout-3col__col-2, [dir="rtl"] .layout-3col__col-4, [dir="rtl"] .layout-3col__col-x:nth-child(2n) {
    padding-left: 13px;
    padding-right: 12px;
  }
}

@media (min-width: 999px) {
  .layout-3col__full {
    float: left;
    width: 100%;
    margin-left: 0%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__full {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  .layout-3col__left-content {
    float: left;
    width: 66.66667%;
    margin-left: 0%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__left-content {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  .layout-3col__right-content {
    float: left;
    width: 66.66667%;
    margin-left: 33.33333%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__right-content {
    float: right;
    margin-right: 33.33333%;
    margin-left: -100%;
  }
  .layout-3col__left-sidebar, .layout-3col__first-left-sidebar, .layout-3col__second-left-sidebar {
    clear: right;
    float: right;
    width: 33.33333%;
    margin-right: 66.66667%;
    margin-left: -100%;
  }
  [dir="rtl"] .layout-3col__left-sidebar, [dir="rtl"] .layout-3col__first-left-sidebar, [dir="rtl"] .layout-3col__second-left-sidebar {
    clear: left;
  }
  [dir="rtl"] .layout-3col__left-sidebar, [dir="rtl"] .layout-3col__first-left-sidebar, [dir="rtl"] .layout-3col__second-left-sidebar {
    float: left;
    margin-left: 66.66667%;
    margin-right: -100%;
  }
  .layout-3col__right-sidebar {
    clear: right;
    float: right;
    width: 33.33333%;
    margin-right: 0%;
    margin-left: -100%;
  }
  [dir="rtl"] .layout-3col__right-sidebar {
    clear: left;
  }
  [dir="rtl"] .layout-3col__right-sidebar {
    float: left;
    margin-left: 0%;
    margin-right: -100%;
  }
  .layout-3col__col-1, .layout-3col__col-x:nth-child(3n+1) {
    clear: both;
    float: left;
    width: 33.33333%;
    margin-left: 0%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__col-1, [dir="rtl"] .layout-3col__col-x:nth-child(3n+1) {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  .layout-3col__col-2, .layout-3col__col-x:nth-child(3n+2) {
    clear: none;
    float: left;
    width: 33.33333%;
    margin-left: 33.33333%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__col-2, [dir="rtl"] .layout-3col__col-x:nth-child(3n+2) {
    float: right;
    margin-right: 33.33333%;
    margin-left: -100%;
  }
  .layout-3col__col-3, .layout-3col__col-x:nth-child(3n) {
    clear: none;
    float: left;
    width: 33.33333%;
    margin-left: 66.66667%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__col-3, [dir="rtl"] .layout-3col__col-x:nth-child(3n) {
    float: right;
    margin-right: 66.66667%;
    margin-left: -100%;
  }
  .layout-3col__col-4 {
    display: none;
  }
}

.layout-3col__grid-item-container {
  padding-left: 0;
  padding-right: 0;
}

.layout-3col__grid-item-container:before {
  content: "";
  display: table;
}

.layout-3col__grid-item-container:after {
  content: "";
  display: table;
  clear: both;
}

.layout-center {
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 auto;
  max-width: 965px;
}

@media (min-width: 777px) {
  .layout-center {
    padding-left: 12px;
    padding-right: 13px;
  }
  [dir="rtl"] .layout-center {
    padding-left: 13px;
    padding-right: 12px;
  }
}

.layout-center--shared-grid,
.layout-center.layout-3col {
  padding-left: 0;
  padding-right: 0;
}

.layout-swap {
  position: relative;
}

@media (min-width: 555px) {
  .layout-swap {
    padding-top: 48px;
  }
}

@media (min-width: 555px) {
  .layout-swap__top {
    position: absolute;
    top: 0;
    height: 48px;
    width: 100%;
  }
}

.box {
  margin-bottom: 1.5rem;
  border: 5px solid #cccccc;
  padding: 1em;
}

.box__title {
  margin: 0;
}

.box:focus, .box:hover, .box.is-focus, .box--is-focus {
  border-color: #000;
}

.box--highlight {
  border-color: #0072b9;
}

.clearfix::before,
.tabs::before,
.rs-bullets__wrapper-all__outer::before,
.rs-bullets__wrapper-all__inner::before,
.section-we-set-bar-great-physical-therapy .field-collection-container::before,
.rs-bullets__wrapper-outer::before,
.rs-footer-contact__contact-addresses::before,
.rs-header__wrapper-inner::before {
  content: '';
  display: table;
}

.clearfix::after,
.tabs::after,
.rs-bullets__wrapper-all__outer::after,
.rs-bullets__wrapper-all__inner::after,
.section-we-set-bar-great-physical-therapy .field-collection-container::after,
.rs-bullets__wrapper-outer::after,
.rs-footer-contact__contact-addresses::after,
.rs-header__wrapper-inner::after {
  content: '';
  display: table;
  clear: both;
}

.comment__section,
.comments {
  margin: 1.5rem 0;
}

.comment__title {
  margin: 0;
}

.comment__permalink {
  text-transform: uppercase;
  font-size: 75%;
}

.comment--preview,
.comment-preview {
  background-color: #fffadb;
}

.comment--nested,
.indented {
  margin-left: 32px;
}

[dir="rtl"] .comment--nested,
[dir="rtl"] .indented {
  margin-left: 0;
  margin-right: 32px;
}

.hidden,
html.js .js-hidden,
html.js .element-hidden,
html.js .js-hide {
  display: none;
}

.highlight-mark,
.new,
.update {
  color: #c00;
  background-color: transparent;
}

.inline-links,
.inline.links {
  padding: 0;
}

.inline-links__item,
.inline.links li {
  display: inline;
  list-style-type: none;
  padding: 0 1em 0 0;
}

[dir="rtl"] .inline-links__item,
[dir="rtl"] .inline.links li,
.inline.links [dir="rtl"] li {
  display: inline-block;
  padding: 0 0 0 1em;
}

.inline-sibling,
.field-label-inline .field-label,
span.field-label {
  display: inline;
  margin-right: 10px;
}

[dir="rtl"] .inline-sibling,
[dir="rtl"] .field-label-inline .field-label,
.field-label-inline [dir="rtl"] .field-label,
[dir="rtl"] span.field-label {
  margin-right: 0;
  margin-left: 10px;
}

.inline-sibling__child,
.inline-sibling *, .field-label-inline .field-label *, span.field-label * {
  display: inline;
}

.inline-sibling__adjacent,
.inline-sibling + *,
.inline-sibling + * > :first-child,
.inline-sibling + * > :first-child > :first-child, .field-label-inline .field-label + *, span.field-label + *, .field-label-inline .field-label + * > :first-child, span.field-label + * > :first-child, .field-label-inline .field-label + * > :first-child > :first-child, span.field-label + * > :first-child > :first-child {
  display: inline;
}

.field-label-inline .field-label,
.field-label-inline .field-items {
  float: none;
}

.messages,
.messages--status {
  margin: 1.5rem 0;
  position: relative;
  padding: 10px 10px 10px 44px;
  border: 1px solid #0072b9;
}

[dir="rtl"] .messages,
[dir="rtl"] .messages--status {
  padding: 10px 44px 10px 10px;
  background-position: 99% 8px;
}

.messages__icon {
  position: absolute;
  top: 50%;
  left: 10px;
  height: 24px;
  width: 24px;
  margin-top: -12px;
  line-height: 1;
}

[dir="rtl"] .messages__icon {
  left: auto;
  right: 0;
}

.messages__icon path {
  fill: #0072b9;
}

.messages__highlight,
.messages--error .error,
.messages.error .error {
  color: #000;
}

.messages__list {
  margin: 0;
}

.messages__item {
  list-style-image: none;
}

.messages--ok-color,
.messages,
.messages--status,
.ok,
.revision-current {
  background-color: #f6fcff;
  color: #0072b9;
}

.messages--warning-color,
.messages--warning,
.messages.warning,
.warning {
  background-color: #fffce6;
  color: #000;
}

.messages--error-color,
.messages--error,
.messages.error,
.error {
  background-color: #fff0f0;
  color: #c00;
}

.messages--warning,
.messages.warning {
  border-color: #fd0;
}

.messages--warning path, .messages.warning path {
  fill: #fd0;
}

.messages--error,
.messages.error {
  border-color: #c00;
}

.messages--error path, .messages.error path {
  fill: #c00;
}

@media print {
  .print-none,
  .toolbar,
  .action-links,
  .links,
  .book-navigation,
  .forum-topic-navigation,
  .feed-icons {
    display: none;
  }
}

.responsive-video,
.media-youtube-video,
.media-vimeo-preview-wrapper,
.media_embed,
.rs-youtube-video-background__video-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
}

.responsive-video__embed,
.responsive-video iframe,
.media-youtube-video iframe,
.media-vimeo-preview-wrapper iframe,
.media_embed iframe,
.rs-youtube-video-background__video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.responsive-video--4-3 {
  padding-bottom: 75%;
}

.visually-hidden,
.element-invisible,
.element-focusable,
.breadcrumb__title,
.main-navigation .block-menu .block__title,
.main-navigation .block-menu-block .block__title {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  word-wrap: normal;
}

.visually-hidden--off,
.visually-hidden--focusable:active,
.visually-hidden--focusable:focus,
.element-focusable:active,
.element-focusable:focus {
  position: static !important;
  clip: auto;
  height: auto;
  width: auto;
  overflow: visible;
}

.watermark {
  font-family: "Roboto", "Franklin Gothic Medium", Tahoma, Arial, sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  display: block;
  height: 0;
  overflow: visible;
  background-color: transparent;
  color: #eeeeee;
  font-size: 75px;
  line-height: 1;
  text-align: center;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
  word-wrap: break-word;
}

@media print {
  .breadcrumb {
    display: none;
  }
}

.breadcrumb__list {
  margin: 0;
  padding: 0;
}

.breadcrumb__item {
  display: inline;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.more-link,
.more-help-link {
  font-weight: 300;
  font-family: "Roboto", "Franklin Gothic Medium", Tahoma, Arial, sans-serif;
  text-decoration: none;
  padding: 0;
  display: block;
  text-align: left;
}

.more-link:after,
.more-help-link:after {
  content: '>';
  padding: 0 0 0 0.5rem;
}

[dir="rtl"] .more-link,
[dir="rtl"] .more-help-link {
  text-align: left;
}

.more-link__help-icon,
.more-help-link a {
  padding: 1px 0 1px 20px;
  background-image: url(../sass/navigation/more-link/more-link__help-icon.svg);
  background-position: 0 50%;
  background-repeat: no-repeat;
}

[dir="rtl"] .more-link__help-icon,
[dir="rtl"] .more-help-link a,
.more-help-link [dir="rtl"] a {
  padding: 1px 20px 1px 0;
  background-position: 100% 50%;
}

.nav-menu__item,
.menu__item {
  list-style-image: url(../sass/navigation/nav-menu/leaf.svg);
  list-style-type: square;
}

.nav-menu__item.is-expanded, .nav-menu__item--is-expanded,
.is-expanded.menu__item,
.menu__item.is-expanded {
  list-style-image: url(../sass/navigation/nav-menu/expanded.svg);
  list-style-type: circle;
}

.nav-menu__item.is-collapsed, .nav-menu__item--is-collapsed,
.is-collapsed.menu__item,
.menu__item.is-collapsed {
  list-style-image: url(../sass/navigation/nav-menu/collapsed.svg);
  list-style-type: disc;
}

[dir="rtl"] .nav-menu__item.is-collapsed, [dir="rtl"] .nav-menu__item--is-collapsed,
[dir="rtl"] .is-collapsed.menu__item,
[dir="rtl"] .menu__item.is-collapsed {
  list-style-image: url(../sass/navigation/nav-menu/collapsed-rtl.svg);
}

.nav-menu__link.is-active, .nav-menu__link--is-active,
.menu a.active {
  color: #000;
}

.navbar,
.main-navigation .links,
.main-navigation .menu {
  margin: 0;
  padding: 0;
  text-align: left;
}

[dir="rtl"] .navbar,
[dir="rtl"] .main-navigation .links,
.main-navigation [dir="rtl"] .links,
[dir="rtl"]
.main-navigation .menu,
.main-navigation [dir="rtl"] .menu {
  text-align: right;
}

.navbar__item,
.navbar li, .main-navigation .links li,
.main-navigation .menu li {
  float: left;
  padding: 0 10px 0 0;
  list-style-type: none;
  list-style-image: none;
}

[dir="rtl"] .navbar__item, [dir="rtl"]
.navbar li, [dir="rtl"] .main-navigation .links li, .main-navigation [dir="rtl"] .links li, [dir="rtl"]
.main-navigation .menu li,
.main-navigation [dir="rtl"] .menu li {
  float: right;
  padding: 0 0 0 10px;
}

.pager {
  clear: both;
  padding: 0;
  text-align: center;
}

@media print {
  .pager {
    display: none;
  }
}

.pager__item,
.pager__current-item,
.pager-current,
.pager-item,
.pager-first,
.pager-previous,
.pager-next,
.pager-last,
.pager-ellipsis {
  display: inline;
  padding: 0 .5em;
  list-style-type: none;
  background-image: none;
}

.pager__current-item,
.pager-current {
  font-weight: bold;
}

.skip-link {
  display: block;
  padding: 2px 0 3px;
  text-align: center;
}

.skip-link:link, .skip-link:visited {
  background-color: #666666;
  color: #fff;
}

.skip-link__wrapper {
  margin: 0;
}

@media print {
  .skip-link__wrapper {
    display: none;
  }
}

.tabs {
  margin: 1.125rem 0 0;
  line-height: 1.875rem;
  border-bottom: 1px solid #cccccc \0/ie;
  background-image: linear-gradient(to top, #cccccc 1px, transparent 1px);
  padding: 0 2px;
  list-style: none;
  white-space: nowrap;
}

@media print {
  .tabs {
    display: none;
  }
}

.tabs__tab {
  float: left;
  margin: 0 3px;
  border: 1px solid #cccccc;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-color: transparent;
  border-bottom: 0 \0/ ie;
  overflow: hidden;
  background: #dddddd;
}

[dir="rtl"] .tabs__tab {
  float: right;
}

.tabs__tab.is-active {
  border-bottom-color: #fff;
}

.tabs__tab-link {
  padding: 0 1.5rem;
  display: block;
  text-decoration: none;
  transition: color .3s, background .3s, border .3s;
  text-shadow: #fff 0 1px 0;
  color: #000;
  background: #dddddd;
  letter-spacing: 1px;
}

.tabs__tab-link:focus, .tabs__tab-link:hover {
  background: #eaeaea;
}

.tabs__tab-link:active, .tabs__tab-link.is-active, .tabs__tab-link--is-active {
  background: #fff;
  text-shadow: none;
}

.tabs--off,
.views-displays .secondary {
  margin: 0;
  border-bottom: 0;
  padding: 0;
  background-image: none;
}

.tabs--secondary {
  margin-top: 1.5rem;
  font-size: 0.875rem;
  background-image: none;
}

.tabs + .tabs--secondary {
  margin-top: 0;
  background-image: linear-gradient(to top, #cccccc 1px, transparent 1px);
}

.tabs--secondary .tabs__tab {
  margin: 0.5625rem 0.1875rem;
  border: 0;
  background: transparent;
}

[dir="rtl"] .tabs--secondary .tabs__tab {
  float: right;
}

.tabs--secondary .tabs__tab.is-active {
  border-bottom-color: transparent;
}

.tabs--secondary .tabs__tab-link {
  border: 1px solid #cccccc;
  border-radius: 24px;
  color: #666666;
  background: #f1f1f1;
  letter-spacing: normal;
}

.tabs--secondary .tabs__tab-link:focus, .tabs--secondary .tabs__tab-link:hover {
  color: #333333;
  background: #dddddd;
  border-color: #999999;
}

.tabs--secondary .tabs__tab-link:active, .tabs--secondary .tabs__tab-link.is-active, .tabs--secondary .tabs__tab-link--is-active {
  color: white;
  text-shadow: #333333 0 1px 0;
  background: #666666;
  border-color: black;
}

.autocomplete,
.form-autocomplete {
  background-image: url(../sass/forms/autocomplete/throbber-inactive.png);
  background-position: 100% center;
  background-repeat: no-repeat;
}

[dir="rtl"] .autocomplete,
[dir="rtl"] .form-autocomplete {
  background-position: 0% center;
}

.autocomplete__list-wrapper,
#autocomplete {
  border: 1px solid;
  overflow: hidden;
  position: absolute;
  z-index: 100;
}

.autocomplete__list,
#autocomplete ul {
  list-style: none;
  list-style-image: none;
  margin: 0;
  padding: 0;
}

.autocomplete__list-item,
#autocomplete li {
  background: #fff;
  color: #000;
  cursor: default;
  white-space: pre;
}

.autocomplete__list-item.is-selected, .autocomplete__list-item--is-selected,
#autocomplete li.is-selected,
#autocomplete .selected {
  background: #0072b9;
  color: #fff;
}

.autocomplete.is-throbbing, .autocomplete--is-throbbing,
.is-throbbing.form-autocomplete,
.form-autocomplete.throbbing {
  background-image: url(../sass/forms/autocomplete/throbber-active.gif);
}

.collapsible-fieldset,
.collapsible {
  position: relative;
}

.collapsible-fieldset__legend,
.collapsible .fieldset-legend {
  display: block;
  padding-left: 15px;
  background-image: url(../sass/forms/collapsible-fieldset/expanded.svg);
  background-position: 4px 50%;
  background-repeat: no-repeat;
}

[dir="rtl"] .collapsible-fieldset__legend,
[dir="rtl"] .collapsible .fieldset-legend,
.collapsible [dir="rtl"] .fieldset-legend {
  padding-left: 0;
  padding-right: 15px;
  background-position: right 4px top 50%;
}

.collapsible-fieldset__summary,
.collapsible .fieldset-legend .summary {
  color: #999999;
  font-size: .9em;
  margin-left: .5em;
}

.collapsible-fieldset.is-collapsed, .collapsible-fieldset--is-collapsed,
.is-collapsed.collapsible,
.collapsible.collapsed {
  border-bottom-width: 0;
  border-left-width: 0;
  border-right-width: 0;
  height: 1em;
}

.collapsible-fieldset.is-collapsed .collapsible-fieldset__wrapper, .collapsible-fieldset--is-collapsed__wrapper, .is-collapsed.collapsible .collapsible-fieldset__wrapper,
.collapsible.collapsed .fieldset-wrapper {
  display: none;
}

.collapsible-fieldset.is-collapsed .collapsible-fieldset__legend, .collapsible-fieldset--is-collapsed__legend, .is-collapsed.collapsible .collapsible-fieldset__legend,
.collapsible.collapsed .fieldset-legend,
.collapsible-fieldset.is-collapsed .collapsible .fieldset-legend,
.collapsible .collapsible-fieldset.is-collapsed .fieldset-legend,
.is-collapsed.collapsible .fieldset-legend {
  background-image: url(../sass/forms/collapsible-fieldset/collapsed.svg);
  background-position: 4px 50%;
}

[dir="rtl"] .collapsible-fieldset.is-collapsed .collapsible-fieldset__legend, [dir="rtl"] .collapsible-fieldset--is-collapsed__legend, [dir="rtl"] .is-collapsed.collapsible .collapsible-fieldset__legend,
[dir="rtl"] .collapsible.collapsed .fieldset-legend,
.collapsible.collapsed [dir="rtl"] .fieldset-legend,
[dir="rtl"] .collapsible-fieldset.is-collapsed .collapsible .fieldset-legend,
.collapsible [dir="rtl"] .collapsible-fieldset.is-collapsed .fieldset-legend,
[dir="rtl"] .is-collapsed.collapsible .fieldset-legend {
  background-image: url(../sass/forms/collapsible-fieldset/collapsed-rtl.svg);
  background-position: right 4px top 50%;
}

.form-item {
  margin: 1.5rem 0;
}

.form-item__required,
.form-required {
  color: #c00;
}

.form-item__description,
.form-item .description {
  font-size: 0.875rem;
}

.form-item--inline div,
.form-item--inline label, .container-inline .form-item div, .container-inline .form-item label {
  display: inline;
}

.form-item--inline__exception,
.container-inline .fieldset-wrapper {
  display: block;
}

.form-item--tight,
.form-item--radio,
.form-type-radio,
.form-type-checkbox,
.password-parent,
.confirm-parent,
table .form-item {
  margin: 0;
}

.form-item--radio .form-item__label, .form-item--radio__label, .form-type-radio .form-item__label,
.form-type-checkbox .form-item__label,
label.option {
  display: inline;
  font-weight: normal;
}

.form-item--radio .form-item__description, .form-item--radio__description, .form-type-radio .form-item__description,
.form-type-checkbox .form-item__description,
.form-type-radio .description,
.form-type-checkbox .description,
.form-item--radio .form-item .description,
.form-item .form-item--radio .description,
.form-type-radio .form-item .description,
.form-item .form-type-radio .description,
.form-type-checkbox .form-item .description,
.form-item
.form-type-checkbox .description {
  margin-left: 1.4em;
}

.form-item.is-error .form-item__widget, .form-item--is-error__widget,
.form-item.is-error input,
.form-item.is-error textarea,
.form-item.is-error select,
.form-item--is-error input,
.form-item--is-error textarea,
.form-item--is-error select,
.form-item input.error,
.form-item textarea.error,
.form-item select.error {
  border: 1px solid #c00;
}

.form-table__sticky-header,
.sticky-header {
  position: fixed;
  visibility: hidden;
  margin-top: 0;
  background-color: #fff;
}

.form-table__sticky-header.is-sticky, .form-table__sticky-header--is-sticky,
.is-sticky.sticky-header {
  visibility: visible;
}

.form-table__header,
.form-table th,
form table th {
  border-bottom: 3px solid #cccccc;
  padding-right: 1em;
  text-align: left;
}

[dir="rtl"] .form-table__header,
[dir="rtl"] .form-table th,
.form-table [dir="rtl"] th,
[dir="rtl"] form table th,
form table [dir="rtl"] th {
  text-align: right;
  padding-left: 1em;
  padding-right: 0;
}

.form-table__body,
.form-table tbody,
form table tbody {
  border-top: 1px solid #cccccc;
}

.form-table__row,
.form-table tbody tr,
form table tbody tr {
  padding: .1em .6em;
  border-bottom: 1px solid #cccccc;
  background-color: #eeeeee;
}

.form-table__row:nth-child(even),
.form-table tbody tr:nth-child(even),
form table tbody tr:nth-child(even) {
  background-color: #fff;
}

.form-table__row.is-active, .form-table__row--is-active,
.form-table tbody tr.is-active,
form table tbody tr.is-active,
td.active {
  background-color: #dddddd;
}

.form-table__row.is-disabled, .form-table__row--is-disabled,
.form-table tbody tr.is-disabled,
form table tbody tr.is-disabled,
td.menu-disabled {
  background: #cccccc;
}

.form-table__row.is-selected, .form-table__row--is-selected,
.form-table tbody tr.is-selected,
form table tbody tr.is-selected,
tr.selected td {
  background: #fffdf0;
}

.form-table__list,
.form-table ul,
form table ul {
  margin: 0;
}

.form-table__narrow-column,
.form-table th.form-table__narrow-column,
form table th.form-table__narrow-column,
td .checkbox,
th .checkbox {
  width: -moz-min-content;
  width: -webkit-min-content;
  text-align: center;
}

.progress-bar,
.progress {
  font-weight: bold;
}

.progress-bar__bar,
.progress .bar {
  border-radius: 3px;
  margin: 0 .2em;
  border: 1px solid #666666;
  background-color: #cccccc;
}

.progress-bar__fill,
.progress .filled {
  height: 1.5em;
  width: 5px;
  background: #0072b9 url(../sass/forms/progress-bar/progress-bar.gif) repeat 0 0;
}

.progress-bar__percentage,
.progress .percentage {
  float: right;
}

[dir="rtl"] .progress-bar__percentage,
[dir="rtl"] .progress .percentage,
.progress [dir="rtl"] .percentage {
  float: left;
}

.progress-bar--inline,
.ajax-progress-bar {
  width: 16em;
  display: inline-block;
}

[dir="rtl"] .progress-bar--inline,
[dir="rtl"] .ajax-progress-bar {
  float: right;
}

.progress-throbber,
.ajax-progress {
  display: inline-block;
}

[dir="rtl"] .progress-throbber,
[dir="rtl"] .ajax-progress {
  float: right;
}

.progress-throbber__widget,
.ajax-progress .throbber {
  background: url(../sass/forms/progress-throbber/progress-throbber.gif) no-repeat 0 -18px transparent;
  float: left;
  height: 15px;
  margin: 2px;
  width: 15px;
}

[dir="rtl"] .progress-throbber__widget,
[dir="rtl"] .ajax-progress .throbber,
.ajax-progress [dir="rtl"] .throbber {
  float: right;
}

.progress-throbber__widget-in-tr,
tr .ajax-progress .throbber {
  margin: 0 2px;
}

.progress-throbber__message,
.ajax-progress .message {
  padding-left: 20px;
}

.resizable-textarea,
.resizable-textarea textarea {
  width: 100%;
  vertical-align: bottom;
}

.resizable-textarea__grippie,
.resizable-textarea .grippie {
  background: url(../sass/forms/resizable-textarea/grippie.png) no-repeat center 2px #eeeeee;
  border: 1px solid #cccccc;
  border-top-width: 0;
  cursor: s-resize;
  height: 9px;
  overflow: hidden;
}

.table-drag__wrapper,
body.drag {
  cursor: move;
}

.table-drag__item,
tr.drag {
  background-color: #fffadb;
}

.table-drag__item-previous,
tr.drag-previous {
  background-color: #fff7c2;
}

.table-drag__handle,
.tabledrag-handle {
  cursor: move;
  float: left;
  height: 1.7em;
  margin-left: -1em;
  overflow: hidden;
  text-decoration: none;
  font-size: 12px;
}

[dir="rtl"] .table-drag__handle,
[dir="rtl"] .tabledrag-handle {
  float: right;
  margin-right: -1em;
  margin-left: 0;
}

.table-drag__handle:focus, .table-drag__handle:hover,
.tabledrag-handle:focus,
.tabledrag-handle:hover {
  text-decoration: none;
}

.table-drag__handle-icon,
.tabledrag-handle .handle {
  box-sizing: content-box;
  background: url(../sass/forms/table-drag/handle-icon.png) no-repeat 6px 9px;
  height: 13px;
  margin: -.4em .5em;
  padding: .42em .5em;
  width: 13px;
}

.table-drag__handle.is-hover .table-drag__handle-icon, .table-drag__handle-icon--is-hover, .is-hover.tabledrag-handle .table-drag__handle-icon,
.tabledrag-handle-hover .handle,
.table-drag__handle.is-hover .tabledrag-handle .handle,
.tabledrag-handle .table-drag__handle.is-hover .handle,
.is-hover.tabledrag-handle .handle {
  background-position: 6px -11px;
}

.table-drag__toggle-weight-wrapper,
.tabledrag-toggle-weight-wrapper {
  text-align: right;
}

[dir="rtl"] .table-drag__toggle-weight-wrapper,
[dir="rtl"] .tabledrag-toggle-weight-wrapper {
  text-align: left;
}

.table-drag__toggle-weight,
.tabledrag-toggle-weight {
  font-size: .9em;
}

.table-drag__indentation,
.indentation {
  float: left;
  height: 1.7em;
  margin: -.4em .2em -.4em -.4em;
  padding: .42em 0 .42em .6em;
  width: 20px;
}

[dir="rtl"] .table-drag__indentation,
[dir="rtl"] .indentation {
  float: right;
  margin: -.4em -.4em -.4em .2em;
  padding: .42em .6em .42em 0;
}

.table-drag__tree-child,
.table-drag__tree-child-last,
.tree-child-last,
.table-drag__tree-child-horizontal,
.tree-child-horizontal,
.tree-child {
  background: url(../sass/forms/table-drag/tree-child.png) no-repeat 11px center;
}

[dir="rtl"] .table-drag__tree-child,
[dir="rtl"] .table-drag__tree-child-last,
[dir="rtl"] .tree-child-last,
[dir="rtl"] .table-drag__tree-child-horizontal,
[dir="rtl"] .tree-child-horizontal,
[dir="rtl"] .tree-child {
  background-position: -65px center;
}

.table-drag__tree-child-last,
.tree-child-last {
  background-image: url(../sass/forms/table-drag/tree-child-last.png);
}

.table-drag__tree-child-horizontal,
.tree-child-horizontal {
  background-position: -11px center;
}

.gutter {
  padding-left: 10px;
  padding-right: 10px;
}

/*
  - Wrap a row in a container, using a mixin, placeholder or class
  - All parameters have defaults set
  - Parameters:
      - $max-width {number} : defaults to $site-width variable value;
      - $centered {boolean} : defaults to true;
      - $gutter {number} : defaults to $gutter variable value;
*/
.container, .layout-center {
  max-width: 985px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
}

/*
  - Used to wrap grid columns
  - Must be wrapped in a container
  - All parameters have defaults set
  - Parameters:
      - $clearfix {boolean} : defaults to true, optionally set to false for inline-block or flex grids
      - $flex {boolean}: defaults to false;
      - $gutter {number}: defaults to $gutter variable value
*/
.row {
  margin-left: -10px;
  margin-right: -10px;
}

.row::before {
  content: '';
  display: table;
}

.row::after {
  content: '';
  display: table;
  clear: both;
}

/*-------------------------------------*/
/* MIXIN: COLUMN */
/*
  - Wrap in a row, which resets first and last column's left and right gutters, respectively
  - All parameters have defaults set
  - Parameters:
      $width {number} : defaults to false, i.e. width not set;
      $float {string} : defaults to left. Optionally set to right, none or false
      $gutter {number} : defaults to $gutter variable value;
*/
p {
  margin: 0 0 1rem;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
}

.font-sm {
  font-size: 90%;
}

.font-sm-x {
  font-size: 80%;
}

.font-sm-xx {
  font-size: 70%;
}

.font-lg {
  font-size: 110%;
}

.font-lg-x {
  font-size: 120%;
}

.font-lg-xx {
  font-size: 130%;
}

.font-family-1 {
  font-family: "Roboto", "Franklin Gothic Medium", Tahoma, Arial, sans-serif;
}

.font-family-2 {
  font-family: "Roboto", "Franklin Gothic Medium", Tahoma, Arial, sans-serif;
}

.font-uppercase {
  text-transform: uppercase;
}

.font-bold {
  font-weight: 700;
}

.link-underline, a.link-underline {
  text-decoration: underline;
}

.clearfix:after, .tabs:after, .rs-bullets__wrapper-all__outer:after, .rs-bullets__wrapper-all__inner:after, .section-we-set-bar-great-physical-therapy .field-collection-container:after, .rs-bullets__wrapper-outer:after, .rs-footer-contact__contact-addresses:after, .rs-header__wrapper-inner:after {
  content: '';
  display: table;
  clear: both;
}

.center {
  text-align: center;
}

.vertical-center {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.dead-center, .colorbox-load:after {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.inline-block {
  display: inline-block;
}

.inline-block-top {
  display: inline-block;
  vertical-align: top;
}

.full-width, .rs-footer-contact__button {
  width: 100%;
  display: block;
}

.full-width-window, .rs-homepage__insurance, .footer, .rs-footer-privacy {
  padding-left: 600rem;
  padding-right: 600rem;
  margin-left: -600rem;
  margin-right: -600rem;
}

.spacing-v {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.spacing-2-v {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.spacing-3-v {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.spacing-4-v {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.spacing-half-v {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.body-inner-wrapper {
  overflow-x: hidden;
  width: 100%;
  height: 100%;
}

.icon-left, .rs-youtube-video-background__booking a:before {
  margin-right: 1rem;
}

.icon-left-lg, .rs-homepage__booking a:before {
  margin-right: 2rem;
}

.icon-right, .rs-homepage__booking a:before {
  margin-left: 1rem;
}

.icon-right-lg {
  margin-left: 2rem;
}

@font-face {
  font-family: "icomoon3";
  src: url("../font-icons/fonts/icomoon.eot?ly6dff");
  src: url("../font-icons/fonts/icomoon.eot?ly6dff#iefix") format("embedded-opentype"), url("../font-icons/fonts/icomoon.ttf?ly6dff") format("truetype"), url("../font-icons/fonts/icomoon.woff?ly6dff") format("woff"), url("../font-icons/fonts/icomoon.svg?ly6dff#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^='icon-'], [class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon3" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-certificate-icomoon:before {
  content: '\e900';
}

.icon-play:before {
  content: '\f04b';
}

.icon-twitter:before {
  content: '\f099';
}

.icon-youtube-square:before {
  content: '\f166';
}

.icon-smiley:before {
  content: '\e905';
}

.icon-insurance-card:before {
  content: '\e906';
}

.icon-facebook-icon:before {
  content: '\e907';
}

.icon-dotted-line:before {
  content: '\e908';
}

.icon-dc-location:before {
  content: '\e909';
}

.icon-clock:before {
  content: '\e90a';
}

.icon-clipboard:before {
  content: '\e90b';
}

.icon-appt-calendar:before {
  content: '\e911';
}

.icon-cart:before {
  content: '\e93a';
}

.icon-instagram:before {
  content: '\ea92';
}

button, .button, .button--white, .button--green, input[type='submit'], #search-block-form input[type='submit']#edit-submit, .rs-homepage__booking, .view-team-members .rs-team__filters-only-therapists, .node-team-member .rs-therapist__location-button, .rs-team__hiring a, .rs-location__gmap-link a, .rs-getting-there__button a, .rs-youtube-video-background__booking {
  font-family: "Roboto", "Franklin Gothic Medium", Tahoma, Arial, sans-serif;
  text-align: center;
  text-decoration: none;
  outline: none;
  padding: .3em .6em;
  display: inline-block;
  cursor: pointer;
}

.button--sm {
  font-size: 90%;
  padding: .2em .6em;
}

.button--lg {
  font-size: 120%;
  padding: .5em .8em;
}

.button--lg-x {
  font-size: 130%;
  padding: .6em 1em;
}

.button--lg-xx {
  font-size: 140%;
  padding: 1em;
}

button, .button {
  color: #fff;
  background-color: #dd3a31;
  border: 1px solid #dd3a31;
  font-weight: 500;
  text-transform: uppercase;
}

button:hover, button:focus, .button:hover, .button:focus {
  color: #fff;
  background-color: #e4645d;
}

.button--white {
  color: #dd3a31;
  background-color: #fff;
  border: 1px solid #dd3a31;
  font-weight: 500;
  text-transform: uppercase;
}

.button--white:hover, .button--white:focus {
  color: #dd3a31;
  background-color: white;
}

.button--white:hover, .button--white:focus {
  color: #a50b01;
}

.button--green {
  color: #fff;
  background-color: #0c8400;
  border: 1px solid #0c8400;
  font-weight: 500;
  text-transform: uppercase;
}

.button--green:hover, .button--green:focus {
  color: #fff;
  background-color: #11b700;
}

label {
  font-family: "Roboto", "Franklin Gothic Medium", Tahoma, Arial, sans-serif;
}

.input-text, select, input[type='text'], input[type='email'], input[type='tel'], input[type='number'], textarea {
  font-family: "Roboto", "Franklin Gothic Medium", Tahoma, Arial, sans-serif;
  font-size: 130%;
  border: none;
  background-color: #fdece4;
  padding: 1rem 1.5rem;
  width: 100%;
}

.input-text ::placeholder, select ::placeholder, input[type='text'] ::placeholder, input[type='email'] ::placeholder, input[type='tel'] ::placeholder, input[type='number'] ::placeholder, textarea ::placeholder {
  color: #1f2131;
  font-family: "Roboto", "Franklin Gothic Medium", Tahoma, Arial, sans-serif;
  font-size: 90%;
}

.input-text:focus, select:focus, input[type='text']:focus, input[type='email']:focus, input[type='tel']:focus, input[type='number']:focus, textarea:focus {
  border: 1px solid #dd3a31;
  outline: 0;
}

html select {
  padding: 1rem 5rem 1rem 1rem;
  line-height: 1;
  border-radius: 0;
  appearance: none;
  overflow: hidden;
  background: #fdece4 url(../images/arrow-dropdown.svg) no-repeat;
  background-size: 1.5rem;
  background-position: calc(100% - 1rem) 50%;
}

html select:hover {
  cursor: pointer;
}

.view-filters {
  margin-bottom: 2rem;
}

.form-field-wrap, .form-item {
  margin: .7rem 0;
}

button, input[type='submit'] {
  color: #fff;
  background-color: #dd3a31;
  border: 1px solid #dd3a31;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 130%;
  padding: .6em 1em;
  font-weight: 700;
  padding: .6em 1.6em;
}

button:hover, button:focus, input[type='submit']:hover, input[type='submit']:focus {
  color: #fff;
  background-color: #e4645d;
}

@media only screen and (min-width: 730px) {
  .node-type-blog .node-blog {
    padding-left: 10px;
    padding-right: 10px;
    float: left;
    width: calc(100% - 160px);
  }
}

.node-type-blog header {
  margin-bottom: 1rem;
}

.node-type-blog .layout-3col {
  margin-left: 0;
  margin-right: 0;
  padding-top: 0;
}

@media (min-width: 777px) {
  .node-type-blog .layout-3col {
    margin-left: 0;
    margin-right: 0;
  }
}

.node-type-blog .field-items a {
  word-wrap: break-word;
}

@media only screen and (min-width: 730px) {
  .node-type-blog article.node-blog header {
    padding-right: 10px;
    width: 140px;
    float: left;
  }
}

@media only screen and (min-width: 730px) {
  .node-type-blog .field-name-body {
    padding-left: 10px;
    padding-right: 10px;
    width: calc(100% - 140px);
    float: left;
  }
}

.node-type-blog .links.inline {
  clear: both;
  /*a {
      color: $color-brand-red;
		}*/
}

@media only screen and (min-width: 730px) {
  .node-type-blog .links.inline {
    margin-left: 150px;
  }
}

.rs-blog__banner img {
  vertical-align: middle;
}

.rs-blog__banner + h1 {
  font-size: 100%;
  line-height: 1.2rem;
  background-color: rgba(255, 255, 255, 0.6);
  padding: 1rem 1.5rem;
  margin: 0;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  word-wrap: break-word;
  color: #1f2131;
  letter-spacing: 0;
}

@media only screen and (min-width: 730px) {
  .rs-blog__banner + h1 {
    font-size: 2rem;
    line-height: 2.2rem;
  }
}

.rs-blog__banner-wrapper {
  position: relative;
}

.rs-blog__submitted {
  color: #999;
  font-family: "Roboto", "Franklin Gothic Medium", Tahoma, Arial, sans-serif;
  font-weight: 200;
  font-size: 110%;
}

.rs-blog__author {
  word-wrap: break-word;
}

.rs-blog__by {
  font-family: "Roboto", "Franklin Gothic Medium", Tahoma, Arial, sans-serif;
  font-weight: 200;
  font-size: 110%;
  color: #999;
}

.rs-blog__by span {
  color: #dd3a31;
  font-weight: 700;
}

.rs-blog__by span.rs-blog__honorific {
  color: #1f2131;
  font-weight: 400;
  display: block;
}

.rs-blog__by a {
  text-decoration: none;
}

@media only screen and (min-width: 730px) {
  #block-minimal-share-minimal-share-sticky-wrapper {
    padding-left: 10px;
    float: left;
  }
}

.block-minimal-share {
  width: 165px !important;
  position: unset !important;
  margin: 0 auto;
}

@media only screen and (min-width: 730px) {
  .block-minimal-share {
    width: 75px !important;
    margin: 0;
    position: fixed !important;
  }
}

.block-minimal-share .block__title {
  color: #fff;
  background-color: #e63a2e;
  text-transform: uppercase;
  line-height: 1.2;
  font-size: 80%;
  margin: 0;
  padding: -1.5rem;
  text-align: center;
}

.minimal-share {
  background-color: #dfdfdf;
  padding: 20px;
  height: 75px;
}

@media only screen and (min-width: 730px) {
  .minimal-share {
    height: auto;
  }
}

.minimal-share a:last-of-type {
  margin: 0;
}

.minimal-share a {
  border-radius: 5%;
  width: 35px;
  margin-right: 5px;
  float: left;
}

@media only screen and (min-width: 730px) {
  .minimal-share a {
    float: none;
  }
}

.minimal-share a.icon {
  font-family: icomoon;
}

.minimal-share a.icon.facebook::before {
  content: '\e907';
}

.minimal-share a.icon.twitter::before {
  content: '\f099';
}

.minimal-share a.icon.gplus::before {
  content: '\ea8b';
}

.minimal-share > * {
  margin: 0 0 1rem;
  display: block;
}

.links .blog_usernames_blog {
  display: none !important;
}

@media only screen and (min-width: 730px) {
  .page-blog main {
    width: 66%;
    float: left;
    padding-left: 10px;
    padding-right: 10px;
    padding-left: 0;
  }
}

@media only screen and (min-width: 730px) {
  .rs-blog-list__region {
    width: 33%;
    float: right;
    padding-left: 10px;
    padding-right: 10px;
    margin: 96px 0 0;
    padding-right: 0;
  }
}

.rs-blog--featured {
  position: relative;
  min-height: 120px;
}

.rs-blog--featured img {
  vertical-align: middle;
}

.view-mode-featured {
  border-bottom: 2px #a50b01 solid;
  margin-bottom: 3rem;
  padding-bottom: 3rem;
}

.view-mode-featured .field-type-text-with-summary {
  font-size: 110%;
}

.rs-feature--title {
  margin: 2rem 0 0 0;
  font-size: 2rem;
  color: #dd3a31;
  line-height: 1.2;
  font-weight: 500;
}

.rs-feature--title a {
  color: inherit;
  text-decoration: none;
}

.rs-teaser--title {
  font-size: 1.45rem;
  line-height: 1.3;
  color: #dd3a31;
  margin: 0;
  font-weight: 500;
}

.rs-teaser--title a {
  color: inherit;
  text-decoration: none;
}

.page-blog .view-id-blog .view-filters {
  display: none;
}

@media only screen and (min-width: 730px) {
  .page-blog .views-row:not(.rs-blog__featured-row) .node {
    display: flex;
    margin-left: -10px;
    margin-right: -10px;
  }
  .page-blog .views-row:not(.rs-blog__featured-row) .node:after {
    content: '';
    display: table;
    clear: both;
  }
}

@media only screen and (min-width: 730px) {
  .page-blog .views-row:not(.rs-blog__featured-row) .node .group-left {
    float: left;
    padding-left: 10px;
    padding-right: 10px;
    flex: 1;
  }
}

@media only screen and (min-width: 730px) {
  .page-blog .views-row:not(.rs-blog__featured-row) .node .group-right {
    float: left;
    padding-left: 10px;
    padding-right: 10px;
    flex: 1;
  }
}

.page-blog .node {
  margin: 0 0 2.5rem;
}

.page-blog .node .more-link {
  font-weight: 300;
  font-family: "Roboto", "Franklin Gothic Medium", Tahoma, Arial, sans-serif;
  text-decoration: none;
  padding: 0;
  display: block;
  text-align: left;
}

.page-blog .node .more-link:after {
  content: '>';
  padding: 0 0 0 0.5rem;
}

.page-blog .node .field-type-text-with-summary {
  font-family: "Roboto", "Franklin Gothic Medium", Tahoma, Arial, sans-serif;
  display: inline-block;
}

.pager {
  font-family: "Roboto", "Franklin Gothic Medium", Tahoma, Arial, sans-serif;
  font-size: 110%;
  line-height: 1.2;
  text-transform: uppercase;
  text-align: left;
}

.pager li {
  display: inline-block;
  height: 3rem;
}

.pager .pager-ellipsis,
.pager .pager-current,
.pager .pager-last,
.pager .pager-first {
  display: none;
}

.pager li:not(.pager-current) {
  cursor: pointer;
  color: #fff;
  position: relative;
  background-color: #1f2131;
}

.pager li:not(.pager-current) a {
  color: #1f2131;
}

.pager li:not(.pager-current) a:after {
  content: '';
  background: url(../images/right-arrow.svg) no-repeat center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  height: 1.5rem;
  width: 1.5rem;
}

.pager li:not(.pager-current).pager-previous {
  margin-right: 0.5rem;
}

.pager li:not(.pager-current).pager-previous a:after {
  background-image: url(../images/left-arrow.svg);
}

.pager li:not(.pager-current) .pager-next a:after {
  background-image: url(../images/right-arrow.svg);
}

.pager li:not(.pager-current):hover {
  background-color: #dd3a31;
}

.pager li a {
  color: inherit;
  text-decoration: none;
}

.pager-current {
  background-color: #dd3a31;
  color: #fff;
}

.pager__current-item, .pager-current {
  font-weight: 400;
}

.pager__item, .pager__current-item, .pager-current, .pager-item, .pager-first, .pager-previous, .pager-next, .pager-last, .pager-ellipsis {
  padding: 0;
  margin-right: -5px;
}

.pager-current, ul.pager li a {
  display: inline-block;
  padding: 1rem 1.5rem;
}

#block-views-blog-categories-block {
  background-color: #fdece4;
  padding: 1.5rem;
  margin: 2rem 0 0;
}

#block-views-blog-categories-block .view-content {
  font-family: "Roboto", "Franklin Gothic Medium", Tahoma, Arial, sans-serif;
  font-size: 110%;
  padding: 0 0 0 0.5rem;
}

#block-views-blog-categories-block .view-content a {
  font-weight: 700;
  text-decoration: none;
}

#block-views-blog-categories-block .views-row {
  padding: 0 0 0.5rem;
}

#block-views-blog-categories-block .pager {
  font-size: 80%;
}

#block-views-blog-categories-block .pager-current, #block-views-blog-categories-block li a {
  padding: 0.5rem 1rem;
}

#block-views-testimonial-block {
  color: #fff;
  background: #dd3a31 url(../images/rose-light.svg) no-repeat -20px -20px;
  padding: 2.2rem;
  margin: 2rem 0 0;
}

#block-views-testimonial-block .views-field-title {
  font-family: "Roboto", "Franklin Gothic Medium", Tahoma, Arial, sans-serif;
  text-transform: uppercase;
  font-size: 1rem;
}

#block-views-testimonial-block .views-label-body, #block-views-testimonial-block .field-content {
  position: relative;
}

#block-views-testimonial-block .field-content {
  z-index: 2;
}

#block-views-testimonial-block .field-content p {
  margin: 0 0 0.5rem;
  text-align: left;
}

#block-views-testimonial-block .field-content a {
  color: black;
}

#block-views-testimonial-block .views-field-body {
  font-size: 1.4rem;
  hyphens: auto;
  text-align: justify;
  position: relative;
}

#block-search-form {
  margin: 0 0 1.2rem;
}

#search-block-form .form-item-search-block-form, #search-block-form #edit-actions {
  display: inline-block;
}

#search-block-form .form-item-search-block-form {
  width: calc(100% - 35px);
}

#search-block-form #edit-actions {
  width: 35px;
  margin-left: -5px;
}

#search-block-form #edit-actions .form-submit {
  width: 35px;
  vertical-align: middle;
  position: relative;
  left: 2px;
  top: -1px;
  padding: 4px;
  background-color: #fdece4;
  height: 2rem;
  margin-top: 2px;
}

#search-block-form #edit-actions .form-submit:hover {
  background-color: #dd3a31;
}

#search-block-form #edit-actions .form-submit:focus {
  outline: 0;
}

#search-block-form input[type='text'].form-text {
  padding: .22em;
  vertical-align: middle;
  height: 2rem;
}

#search-block-form input[type='submit']#edit-submit {
  color: #fff;
  background-color: #dd3a31;
  border: 1px solid #dd3a31;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 90%;
  padding: .2em .6em;
  padding: .3em;
}

#search-block-form input[type='submit']#edit-submit:hover, #search-block-form input[type='submit']#edit-submit:focus {
  color: #fff;
  background-color: #e4645d;
}

#block-views-videos-block-2 .cboxElement {
  display: block;
}

#block-views-videos-block-2 img {
  width: 100%;
}

.block__title {
  font-size: 1.2rem;
  text-transform: uppercase;
  line-height: 1.4;
  margin-bottom: 1.2rem;
}

.hp__top-links-video {
  color: #dd3a31;
  font-family: "Roboto", "Franklin Gothic Medium", Tahoma, Arial, sans-serif;
  font-size: 110%;
  font-weight: 700;
  text-decoration: none;
}

.code-block {
  font-family: monospace;
  background: #ebebeb;
  display: block;
  margin: 0 0 1rem;
}

.logo-block__link {
  display: inline-block;
}

.logo__name-and-slogan, .footer__name-and-slogan {
  text-transform: uppercase;
  display: inline-block;
  vertical-align: top;
}

.logo__site-name {
  font-size: 220%;
  line-height: 1.1;
  font-weight: 400;
  margin: 0 0 0 1rem;
}

.logo__site-link, .footer__site-link, .header__site-link {
  color: inherit;
  text-decoration: none;
}

html .layout-3col {
  margin-left: 0;
  margin-right: 0;
}

.gn-menu-main {
  width: 60px;
}

* {
  transition: color, background-color, border, .3s linear;
}

a {
  color: #dd3a31;
  text-decoration: none;
}

a:hover, a:active, a.is-active-trail, a.active-trail, a.active {
  color: #a50b01;
}

.lineage-item-level-0 a {
  color: #a50b01;
}

.lineage-item-level-0 a:hover {
  color: #dd3a31;
}

.field-name-body {
  font-size: 120%;
  line-height: 1.65em;
}

.csshyphens .field-name-body {
  text-align: justify;
  hyphens: auto;
}

body:not(.userid-1) .links.inline .flag {
  display: none;
}

iframe {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.field-collection-view {
  border: none;
}

.field-collection-container {
  margin: 0;
  padding: 0;
  border: none;
}

.region-homepage-top {
  clear: both;
}

body.front .layout-swap {
  padding-top: 0;
}

@media only screen and (min-width: 480px) {
  .region-homepage-middle:after {
    content: '';
    display: table;
    clear: both;
  }
}

.rs-home-icons__wrapper {
  z-index: 1;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.rs-home-icons__icon-wrapper {
  flex: 1 1 225px;
  text-align: center;
}

.rs-home-icons__link {
  display: inline-block;
  padding: 1rem 3rem;
}

.rs-home-icons__link:hover {
  background-color: #fdece4;
}

.rs-home-icons__link:hover .rs-home-icons__hr {
  border-color: #dd3a31;
}

.rs-home-icons__icon {
  width: 100px;
  height: 100px;
  margin: auto;
  display: block;
  background: no-repeat center/contain url("../images/home-icons__services.svg");
}

.rs-home-icons__icon-services {
  background-image: url("../images/home-icons__services.svg");
}

.rs-home-icons__icon-videos {
  background-image: url("../images/home-icons__videos.svg");
}

.rs-home-icons__icon-team {
  background-image: url("../images/home-icons__team.svg");
}

.rs-home-icons__icon-blog {
  background-image: url("../images/home-icons__blog.svg");
}

.rs-home-icons__title {
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  font-size: 1.15rem;
  font-weight: 700;
  text-align: center;
  white-space: nowrap;
}

.rs-home-icons__hr {
  display: block;
  height: 1px;
  width: 45px;
  border-top: 7px solid #1f2131;
  margin: 1rem auto 0.25rem auto;
}

.rs-homepage__insurance {
  font-family: "Roboto", "Franklin Gothic Medium", Tahoma, Arial, sans-serif;
  font-weight: 300;
  color: #55565a;
  font-size: 220%;
  line-height: 1.1;
  text-align: center;
  margin-top: 4rem;
  margin-bottom: 4rem;
  clear: both;
}

@media only screen and (min-width: 480px) {
  .region-homepage-bottom:after {
    content: '';
    display: table;
    clear: both;
  }
}

.rs-homepage__pitch-wrapper, .rs-homepage__map {
  font-family: "Roboto", "Franklin Gothic Medium", Tahoma, Arial, sans-serif;
}

#block-block-13, #block-block-14 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

@media only screen and (min-width: 730px) {
  #block-block-13, #block-block-14 {
    width: 50%;
    float: left;
    padding-left: 3rem;
    padding-right: 3rem;
  }
}

@media only screen and (min-width: 985px) {
  #block-block-13 {
    padding-left: 0;
  }
}

#block-block-14 {
  margin: 3rem 0 0;
}

@media only screen and (min-width: 730px) {
  #block-block-14 {
    margin: 0;
  }
}

@media only screen and (min-width: 985px) {
  #block-block-14 {
    padding-right: 0;
  }
}

.rs-homepage__pitch {
  font-size: 130%;
  line-height: 1.5;
  font-weight: 300;
  color: #55565a;
  text-align: justify;
  hyphens: auto;
}

.rs-homepage__booking {
  color: #fff;
  background-color: #0c8400;
  border: 1px solid #0c8400;
  font-weight: 500;
  text-transform: uppercase;
  margin: 5rem 0 0;
  width: 100%;
}

.rs-homepage__booking:hover, .rs-homepage__booking:focus {
  color: #fff;
  background-color: #11b700;
}

.rs-homepage__booking a {
  color: currentColor;
  text-decoration: none;
  display: inline-block;
}

.rs-homepage__booking a:before {
  content: '\e911';
  font-family: "icomoon3";
  font-size: 150%;
  vertical-align: sub;
}

@media only screen and (min-width: 730px) {
  .rs-homepage__booking {
    font-size: 140%;
    padding: 1em;
  }
}

.rs-homepage__pitch-heading {
  font-size: 180%;
  font-weight: 700;
  line-height: 1.3;
  color: #dd3a31;
}

.rs-homepage__map {
  position: relative;
}

.rs-homepage__map img {
  display: block;
  margin: 0 auto;
}

@media only screen and (min-width: 730px) {
  .rs-homepage__map img {
    margin: 0;
  }
}

.page-physical-therapists h1 {
  margin-bottom: 1rem;
}

#edit-submit-team-members {
  display: none;
}

label[for="edit-field-location-target-id-entityreference-filter"] {
  display: none;
}

.node-teaser .rs-team__honorific {
  text-decoration: none;
  font-weight: 400;
  display: block;
  font-size: .7em;
}

.node-teaser a.rs-team__title-wrapper {
  text-decoration: none;
}

.node-teaser a.rs-team__title-wrapper,
.node-teaser .rs-team__header {
  width: 100%;
  height: 100%;
  display: block;
}

.node-teaser .rs-team__title {
  color: #dd3a31;
}

.node-teaser .rs-team__honorific-sep {
  display: none;
}

.node-teaser.node-team-member a {
  color: #000;
}

.rs-team__article {
  position: relative;
  padding-bottom: 20px;
  height: auto;
  background-repeat: no-repeat;
  background-size: contain;
}

.rs-team__list-size-rs-220x220 {
  width: 45.83333%;
  padding-top: 45.83333%;
}

@media only screen and (min-width: 480px) {
  .rs-team__list-size-rs-220x220 {
    width: 22.91667%;
    padding-top: 22.91667%;
  }
}

.rs-team__list-size-rs-460x220 {
  width: 95.83333%;
  padding-top: 45.83333%;
}

@media only screen and (min-width: 480px) {
  .rs-team__list-size-rs-460x220 {
    width: 47.91667%;
    padding-top: 22.91667%;
  }
}

.rs-team__list-size-rs-220x460 {
  width: 45.83333%;
  padding-top: 95.83333%;
}

@media only screen and (min-width: 480px) {
  .rs-team__list-size-rs-220x460 {
    width: 22.91667%;
    padding-top: 47.91667%;
  }
}

.view-team-members {
  width: 100%;
  max-width: 960px;
}

.view-team-members .rs-team__header {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  margin: .5rem;
}

.view-team-members .rs-team__header h2 {
  font-size: 1.25rem;
  line-height: 1.65rem;
  margin: 0;
}

.view-team-members ul.inline {
  position: absolute;
  bottom: 0;
}

.view-team-members ul.inline .node-readmore {
  display: none;
}

.view-team-members .view-header,
.view-team-members .view-filters {
  float: left;
}

.view-team-members .view-content {
  width: 100%;
  float: left;
}

.view-team-members .views-exposed-form .views-exposed-widget .form-submit {
  margin-top: 1.1em;
}

.view-team-members .views-exposed-form .views-submit-button {
  font-size: 100%;
  padding: 10px 15px;
  line-height: 100%;
}

.view-team-members .views-exposed-form select {
  font-size: 100%;
}

.view-team-members .view-header {
  padding: 10px 15px;
}

.view-team-members .rs-team__filters-only-therapists {
  color: #fff;
  background-color: #dd3a31;
  border: 1px solid #dd3a31;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 100%;
  line-height: 100%;
  margin-top: 1.1em;
  font-weight: bold;
}

.view-team-members .rs-team__filters-only-therapists:hover, .view-team-members .rs-team__filters-only-therapists:focus {
  color: #fff;
  background-color: #e4645d;
}

.rs-pt__element {
  margin-bottom: 2rem;
}

.node-team-member.view-mode-full {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media only screen and (min-width: 730px) {
  .node-team-member.view-mode-full {
    flex-wrap: nowrap;
  }
}

.node-team-member.view-mode-full .rs-pt__pos {
  flex: 1 1 400px;
}

@media only screen and (min-width: 730px) {
  .node-team-member.view-mode-full .rs-pt__pos-left {
    margin-right: 2rem;
  }
}

.node-team-member.view-mode-full ul.links {
  position: absolute;
  z-index: 1;
  right: 0;
  top: -3rem;
}

.title__team-member {
  letter-spacing: 0;
  text-transform: none;
  line-height: 1.4;
}

.title__team-member .rs-team__title {
  font-size: 2.5rem;
  font-weight: 500;
  margin-bottom: 1rem;
  line-height: 1.4;
}

.title__team-member .rs-team__honorific {
  display: block;
  color: #1f2131;
  font-size: 1.8rem;
}

.rs-pt__element-pic img {
  width: 100%;
}

.node-team-member .rs-therapist__location-button {
  color: #fff;
  background-color: #4289c8;
  border: 1px solid #4289c8;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 1rem;
  font-size: 1rem;
}

.node-team-member .rs-therapist__location-button:hover, .node-team-member .rs-therapist__location-button:focus {
  color: #fff;
  background-color: #6aa2d3;
}

.node-team-member .rs-therapist__location-button a {
  color: white;
}

.rs-pt__element-location {
  background-color: #fdece4;
  padding: 2rem 1.5rem 1rem 1.5rem;
  font-size: 1.1rem;
}

.field-name-field-location .field-label {
  font-weight: 500;
  font-family: "Roboto", "Franklin Gothic Medium", Tahoma, Arial, sans-serif;
  font-size: 100%;
  text-transform: uppercase;
  line-height: 1;
  margin-bottom: 1.5rem;
}

.rs-pt__element-credential {
  background-color: #fdece4;
  padding: 2rem 1.5rem 1rem 1.5rem;
}

.rs-pt__element-credential .field-items .field-item {
  margin-bottom: 1rem;
}

.rs-pt__element-credential .field-collection-view {
  margin: 0;
  padding: 0;
}

.rs-pt__element-credential .field-name-field-image {
  float: left;
  margin-right: 2rem;
}

.rs-pt__element-credential .field-name-field-image img {
  width: 85px;
}

.rs-pt__element-credential .field-name-field-text-multi {
  float: left;
  max-width: 300px;
  font-size: 1.1rem;
  line-height: 1.6;
}

.rs-pt__pos-bottom {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}

@media only screen and (min-width: 730px) {
  .rs-pt__pos-bottom .rs-pt__element-pic-rs_220x150 {
    margin-right: 2rem;
  }
}

.rs-pt__pos-bottom .rs-pt__element-pic-rs_220x150,
.rs-pt__pos-bottom .rs-pt__element-hiring {
  flex: 1 1 210px;
  margin-bottom: 2rem;
  display: flex;
  align-items: stretch;
}

.rs-pt__pos-bottom .rs-pt__element-hiring {
  width: 100%;
}

.rs-pt__pos-bottom .rs-pt__element-hiring .block {
  width: 100%;
  background-color: #dd3a31;
}

.rs-team__hiring {
  text-align: center;
  font-family: "Roboto", "Franklin Gothic Medium", Tahoma, Arial, sans-serif;
  font-size: 90%;
  line-height: 1.4;
  color: #fff;
  background-color: #e63a2e;
  padding: 1rem;
  font-weight: 300;
  width: 100%;
}

.rs-team__hiring a {
  color: currentColor;
  text-decoration: none;
  color: #fff;
  background-color: #1f2131;
  border: 1px solid #fff;
  font-weight: 500;
  text-transform: uppercase;
  margin-top: 0.5rem;
}

.rs-team__hiring a:hover, .rs-team__hiring a:focus {
  color: #fff;
  background-color: #333650;
}

@media only screen and (min-width: 480px) {
  .rs-team__hiring {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.rs-team__hiring-exclam {
  font-family: "Roboto", "Franklin Gothic Medium", Tahoma, Arial, sans-serif;
  font-size: 140%;
  font-weight: 800;
  font-style: italic;
  white-space: nowrap;
}

@media only screen and (min-width: 730px) {
  .rs-team__hiring-exclam {
    font-size: 200%;
  }
}

@media only screen and (min-width: 480px) {
  .rs-team__hiring-blurb {
    display: flex;
  }
}

.view-team-member-blogs .view-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media only screen and (min-width: 730px) {
  .view-team-member-blogs .view-content {
    flex-wrap: nowrap;
  }
}

.view-team-member-blogs .view-content .views-row {
  flex: 1 1 400px;
}

@media only screen and (min-width: 730px) {
  .view-team-member-blogs .view-content > div:not(:last-of-type) {
    margin-right: 2rem;
  }
}

.view-team-member-blogs .view-content .rs-left {
  margin-bottom: 1rem;
}

.view-team-member-blogs .views-field-title {
  font-weight: 500;
  font-family: "Roboto", "Franklin Gothic Medium", Tahoma, Arial, sans-serif;
  font-size: 1.3rem;
  text-transform: uppercase;
  line-height: 1;
  margin-bottom: 1.5rem;
}

.view-team-member-blogs .node-teaser {
  margin-bottom: 2.5rem;
}

body.page-node-3 main > h1 {
  display: none;
}

body.page-node-3 footer {
  margin-top: 0;
}

.why-rose__video-title-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 3rem;
}

.why-rose__video-title-wrapper .why-rose__video-wrapper {
  flex: 1 1 560px;
}

.why-rose__video-title-wrapper .why-rose__video-wrapper .media_embed {
  width: 100%;
  max-width: 100%;
}

.why-rose__title-wrapper {
  margin-right: 2rem;
  flex: 1 1 250px;
}

.why-rose__title-wrapper .why-rose__title {
  margin-bottom: 0;
  white-space: nowrap;
  font-size: 2.5rem;
  letter-spacing: 1;
}

.why-rose__title-wrapper .why-rose__subtitle {
  line-height: 1.2;
  font-size: 1.75rem;
  font-weight: 400;
}

.rs-bullets__wrapper-all__outer {
  width: 100vw;
  height: 100%;
  margin-left: calc(50% - 50vw);
  background-color: #f3f3f4;
  padding-top: 4rem;
}

.rs-bullets__wrapper-all__inner {
  max-width: 965px;
  margin: auto;
  position: relative;
}

@media only screen and (min-width: 985px) {
  .media_embed {
    max-width: 75%;
    margin: 0 auto;
  }
}

.rs-bullets__wrapper {
  min-height: 182px;
  margin: 0 auto;
}

@media only screen and (min-width: 985px) {
  .rs-bullets__wrapper {
    width: 360px;
    height: 200px;
    padding-left: 0;
    float: left;
    clear: both;
    background-image: none;
  }
}

.rs-bullets__wrapper-outer {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 0 1rem;
}

@media only screen and (min-width: 985px) {
  .rs-bullets__wrapper-outer {
    padding: 0;
  }
}

.rs-bullets__wrapper-outer .rs-bullets__image .colorbox-load {
  transform: translate(-50%, -50%);
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
}

.rs-bullets__wrapper-outer .rs-bullets__image .colorbox-load:after {
  padding: 5px 10px;
  font-size: 13px;
  color: #fff;
  background-color: #dd3a31;
}

.rs-bullets__wrapper-outer .rs-bullets__image .colorbox-load:hover:after {
  color: #1f2131;
  background-color: #fff;
  border: #999 1px solid;
}

.rs-bullets__wrapper-outer .rs-bullets__image .colorbox-load.rs-bullets__title-hover:after {
  color: #1f2131;
  background-color: #fff;
  border: #999 1px solid;
}

.rs-bullets__wrapper-outer:nth-of-type(odd) .rs-bullets__wrapper {
  float: left;
}

@media only screen and (min-width: 985px) {
  .rs-bullets__wrapper-outer:nth-of-type(odd) .rs-bullets__image:after {
    transform: rotate(-15deg);
    right: 2px;
    left: auto;
    top: 109px;
  }
}

.rs-bullets__wrapper-outer:nth-of-type(odd) .rs-bullets__image {
  left: calc(50% - 20px);
}

.rs-bullets__wrapper-outer:nth-of-type(odd) .rs-bullets__image .colorbox-load {
  right: -2px;
  left: auto;
}

@media only screen and (min-width: 985px) {
  .rs-bullets__wrapper-outer:nth-of-type(odd) .rs-bullets__image .colorbox-load {
    right: auto;
    left: 0;
  }
}

.rs-bullets__wrapper-outer:nth-of-type(even) .rs-bullets__wrapper {
  float: left;
}

@media only screen and (min-width: 985px) {
  .rs-bullets__wrapper-outer:nth-of-type(even) .rs-bullets__wrapper {
    float: right;
  }
}

@media only screen and (min-width: 985px) {
  .rs-bullets__wrapper-outer:nth-of-type(even) .rs-bullets__image:after {
    left: 26px;
    top: 117px;
    right: auto;
    transform: rotate(15deg);
  }
}

.rs-bullets__wrapper-outer:nth-of-type(even) .rs-bullets__image {
  left: calc(50% + 20px);
}

.rs-bullets__wrapper-outer:nth-of-type(even) .rs-bullets__image .colorbox-load {
  right: -2px;
  left: auto;
}

.rs-bullets__wrapper-all__inner section:last-of-type .rs-bullets__image:after {
  display: none;
}

.rs-bullets__image {
  background-position: center;
  background-repeat: no-repeat;
  float: left;
  margin-right: 1.5rem;
  width: 80px;
  height: 80px;
}

@media only screen and (min-width: 730px) {
  .rs-bullets__image {
    height: 100px;
    width: 100px;
    margin-right: 2rem;
  }
}

@media only screen and (min-width: 985px) {
  .rs-bullets__image {
    transform: translate(-50%, -50%);
    display: block;
    position: absolute;
    top: 60px;
    margin: 0;
    width: 115px;
    height: 115px;
  }
}

.rs-bullets__image img {
  border-radius: 50%;
}

@media only screen and (min-width: 730px) {
  .rs-bullets__image:after {
    content: '';
    background: url("/sites/all/themes/rose_new/images/why-rose-line.svg") top left no-repeat;
    width: 30px;
    height: 150px;
    position: absolute;
    display: block;
    left: calc((100px + 2rem) / 2);
    top: 101px;
  }
}

@media only screen and (min-width: 985px) {
  .rs-bullets__image:after {
    left: calc((151px / 2) - 0.5rem);
    z-index: -2;
    top: 116px;
  }
}

.rs-bullets__image-inner {
  height: 100%;
  width: 100%;
  position: relative;
}

.rs-bullets__title {
  font-family: "Roboto", "Franklin Gothic Medium", Tahoma, Arial, sans-serif;
  font-size: 120%;
  font-weight: 700;
  line-height: 1.2;
  color: #dd3a31;
  margin-bottom: 1rem;
  hyphens: auto;
  float: left;
  display: table;
  height: 80px;
  width: calc(100% - 80px - 1.5rem);
  text-align: left;
}

@media only screen and (min-width: 730px) {
  .rs-bullets__title {
    font-size: 160%;
    width: calc(100% - 115px - 2rem);
  }
}

@media only screen and (min-width: 985px) {
  .rs-bullets__title {
    float: none;
    height: auto;
    display: block;
    width: auto;
  }
}

.rs-bullets__title a, .rs-bullets__title .rs-bullets__title-unlinked {
  display: table-cell;
  vertical-align: middle;
}

.rs-bullets__title a:hover, .rs-bullets__title .rs-bullets__title-unlinked:hover {
  color: #1f2131;
}

.rs-bullets__blurb {
  line-height: 1.6;
  padding: 0 0 2rem;
  text-align: justify;
  hyphens: auto;
  float: left;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

@media only screen and (min-width: 730px) {
  .rs-bullets__blurb {
    width: calc(100% - 115px - 2rem);
    margin-top: 0;
    margin-bottom: 0;
  }
}

@media only screen and (min-width: 985px) {
  .rs-bullets__blurb {
    width: auto;
    padding: 0;
    float: none;
    height: 400px;
  }
}

.rs-bullets__blurb a {
  color: inherit;
}

.rs-bullets__blurb a:hover {
  color: #a50b01;
}

.rs-bullets__title .colorbox-load:after, .rs-bullets__blurb .colorbox-load:after {
  content: '';
  background-color: transparent;
}

.rs-bullets__title .colorbox-load:hover:after, .rs-bullets__blurb .colorbox-load:hover:after {
  background-color: transparent;
}

article.node-page .action-links-field-collection-add,
article.node-page .field-collection-description {
  display: none;
}

.footer {
  color: #fff;
  font-family: "Roboto", "Franklin Gothic Medium", Tahoma, Arial, sans-serif;
  background-color: #dd3a31;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 8% auto;
  margin-top: 2em;
}

.footer a {
  color: #fff;
}

.footer a:hover {
  color: #1f2131;
}

.footer a:not(.link-underline) {
  text-decoration: none;
}

.footer .menu {
  font-size: 90%;
  font-weight: 700;
  padding: 0;
}

.footer #block-multiblock-1 h2 {
  display: none;
}

.footer .menu__item {
  list-style: none;
  font-size: 110%;
  margin: 0 0 1rem;
}

.footer .menu__item.first {
  display: none;
}

.footer .menu__item a {
  text-transform: uppercase;
  font-size: 130%;
}

.footer .menu__item a:active, .footer .menu__item a:hover {
  color: #1f2131;
}

.footer__inner-wrapper {
  max-width: 965px;
  width: 100%;
  padding: 2rem 1rem 0 2rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media only screen and (min-width: 985px) {
  .footer__inner-wrapper {
    padding: 2rem 0 0 0;
  }
}

.footer__logo {
  display: inline-block;
  vertical-align: middle;
}

.footer__logo img {
  max-width: 65px !important;
}

.footer__name-and-slogan {
  vertical-align: middle;
}

#block-block-17 {
  margin-right: 1rem;
  flex: 0 1 auto;
  margin-bottom: 1rem;
}

#block-multiblock-1 {
  flex: 0 1 auto;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

#block-multiblock-1 .rs-header-contact__social {
  color: #1f2131;
}

#block-multiblock-1 .rs-header-contact__social:hover {
  color: #fff;
}

#block-multiblock-1 .rs-header-contact__social-fb {
  margin: 0;
}

#block-multiblock-1 rs-header-contact__social-wrapper {
  position: relative;
  right: 4px;
}

#block-multiblock-1 ul.menu {
  margin: 0;
}

@media only screen and (min-width: 730px) {
  #block-multiblock-1 {
    padding-left: 0;
  }
}

#block-block-15 {
  flex: 2 2 100%;
}

@media only screen and (min-width: 985px) {
  #block-block-15 {
    flex: 2 2 calc(50% - 4rem);
    padding-right: 0;
    margin-left: 4rem;
  }
}

#block-block-16 {
  clear: both;
}

.logo-block--footer {
  color: #fff;
}

.logo__site-name--footer {
  font-size: 180%;
}

.rs-footer-contact__booking {
  float: left;
  width: 100%;
}

.rs-footer-contact__button {
  background-color: #1f2131;
  border: 2px solid transparent;
  margin-bottom: 1rem;
  white-space: nowrap;
  font-size: .9rem;
}

@media only screen and (min-width: 730px) {
  .rs-footer-contact__button {
    font-size: 130%;
  }
}

.rs-footer-contact__button:hover {
  color: #fff !important;
  border: 2px solid #fff;
  background-color: #333650;
}

.rs-footer-contact__contact {
  float: left;
  width: 100%;
}

.rs-footer-contact__contact-addresses {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.rs-footer-contact__contact-addresses .rs-footer-contact__info {
  margin-bottom: 2rem;
}

.rs-footer-contact__contact-addresses .rs-footer-contact__info-left {
  flex: 1 1 auto;
  margin-right: 1rem;
}

.rs-footer-contact__contact-addresses .rs-footer-contact__info-right {
  flex: 0 1 auto;
}

.rs-footer-contact__contact-phones {
  margin-bottom: 2rem;
}

#block-block-16 {
  width: 100%;
  flex: 4 0 100%;
}

.rs-footer-privacy {
  font-size: 90%;
  background-color: #1f2131;
  padding: 1rem;
  clear: both;
}

.rs-footer-privacy a {
  text-align: center;
}

.rs-footer-privacy a:hover {
  color: #dd3a31;
}

.rs-footer-privacy .rs-footer-privacy__inner-wrapper {
  max-width: 100vw;
  margin: auto;
  padding: 0 2rem;
}

@media only screen and (min-width: 985px) {
  .rs-footer-privacy .rs-footer-privacy__inner-wrapper {
    max-width: 965px;
    padding: 0;
  }
}

.header {
  font-family: "Roboto", "Franklin Gothic Medium", Tahoma, Arial, sans-serif;
  padding: 0;
  margin-left: calc(50% - 50vw);
  width: 100vw;
  background-color: #1f2131;
}

.header a {
  text-decoration: none;
}

.rs-header__wrapper-inner {
  max-width: 965px;
  margin: auto;
  display: block;
  padding: 3rem 1rem 0.5rem 1rem;
}

@media only screen and (min-width: 850px) {
  .rs-header__wrapper-inner {
    padding: 2rem 1rem;
  }
}

@media only screen and (min-width: 1280px) {
  .rs-header__wrapper-inner {
    padding: 2rem 0;
  }
}

@media only screen and (min-width: 730px) {
  .header-wrap {
    margin-left: -10px;
    margin-right: -10px;
  }
  .header-wrap:after {
    content: '';
    display: table;
    clear: both;
  }
}

.header__logo-and-name {
  display: inline-block;
  position: relative;
  z-index: 1;
}

.header__region {
  margin: 0.5rem 0;
  padding-right: 0;
}

@media only screen and (min-width: 730px) {
  .header__region {
    padding-top: 1.5rem;
    float: right;
    display: inline-block;
    text-align: right;
    margin: 0;
    padding-right: 0;
  }
}

@media only screen and (min-width: 850px) {
  .header__region {
    padding-top: 0;
  }
}

.header__logo {
  display: inline-block;
  vertical-align: middle;
}

.header__logo img {
  max-width: 65px !important;
}

@media only screen and (min-width: 730px) {
  .header__logo img {
    max-width: 70px !important;
  }
}

.header__name-and-slogan {
  height: 71px;
  display: inline-block;
  vertical-align: middle;
  width: 218px;
}

@media only screen and (min-width: 730px) {
  .header__name-and-slogan {
    width: 230px;
  }
}

.header__site-name {
  display: none;
}

.visually-hidden--focusable#main-menu {
  display: none;
}

.rs-header-contact__booking {
  display: inline-block;
}

.rs-header-contact__booking a {
  color: #dd3a31;
  font-weight: 700;
  margin: 0 0 1rem;
  text-transform: uppercase;
  padding: .5rem;
  border: 2px solid transparent;
}

.rs-header-contact__booking a:hover {
  color: #fff;
  border: 2px solid #fff;
  background-color: #a50b01;
}

.rs-header-contact__phone {
  display: inline-block;
  margin: 0 1rem 0 0;
}

.rs-header-contact__phone a {
  color: inherit;
}

.rs-header-contact__social-wrapper {
  display: inline-block;
  vertical-align: middle;
  margin-top: 1rem;
}

@media only screen and (min-width: 730px) {
  .rs-header-contact__social-wrapper {
    margin-top: 0;
  }
}

.rs-header-contact__social {
  color: #fff;
  font-size: 140%;
  margin-left: 0.5rem;
}

.rs-header-contact__social:before {
  font-family: "icomoon3";
}

.rs-header-contact__social-fb:before {
  content: '\e907';
}

.rs-header-contact__social-twitter {
  margin-left: 3px;
}

.rs-header-contact__social-twitter:before {
  content: '\f099';
}

.rs-header-contact__social-yt:before {
  content: '\ea9d';
}

.rs-header-contact__social-instagram:before {
  content: '\ea92';
}

.layout-swap__top {
  position: absolute;
  top: 0;
  width: 100vw;
  left: 0;
  margin: 0;
  padding: 0;
  height: 1px;
}

.region-navigation {
  position: relative;
}

.region-navigation #block-system-main-menu {
  position: static;
}

#block-system-main-menu {
  clear: left;
}

#block-system-main-menu .block__title {
  display: none;
}

#block-system-main-menu .responsive-menus {
  font-family: "Roboto", "Franklin Gothic Medium", Tahoma, Arial, sans-serif;
}

#block-system-main-menu .footer__logo-and-name {
  display: none !important;
}

#block-system-main-menu .menu__item.first {
  display: none;
}

#block-system-main-menu .responsive-menus.responsified {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  opacity: .95;
  height: 27px;
  width: 100px;
}

#block-system-main-menu .responsive-menus.responsified ul {
  margin-bottom: 1rem;
}

#block-system-main-menu .responsive-menus.responsified li {
  display: block;
}

#block-system-main-menu .responsive-menus.responsified li.first {
  display: none;
}

#block-system-main-menu .responsive-menus.responsified span.toggler {
  width: 100px;
  border-radius: 0;
  background: #1f2131;
  float: left;
  color: #fff;
  opacity: .95;
}

#block-system-main-menu .responsive-menus.responsified.responsive-toggled span.toggler {
  width: 190px;
  float: none;
}

#block-system-main-menu .responsive-menus.responsified.responsive-toggled span.toggler:after {
  content: 'x';
  margin-left: 3rem;
}

#block-system-main-menu .responsive-menus.responsified .responsive-menus-simple {
  width: 190px;
  display: block;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
}

#block-system-main-menu .responsive-menus.responsified.responsive-toggled .responsive-menus-simple {
  visibility: visible;
  opacity: .95;
}

#block-system-main-menu .responsive-menus.responsified span.toggler,
#block-system-main-menu .responsive-menus.responsified .responsive-menus-simple {
  background: #1f2131;
  box-shadow: none;
  color: #fff;
  border: 0;
  padding-top: 0.5rem;
}

#block-system-main-menu ul {
  white-space: nowrap;
  padding: 0;
  left: 1rem;
}

@media only screen and (min-width: 850px) {
  #block-system-main-menu ul {
    margin: 0;
    padding: 0;
    position: absolute;
    text-align: right;
    top: 73px;
    right: 1rem;
  }
}

@media only screen and (min-width: 1280px) {
  #block-system-main-menu ul {
    right: calc(calc(100vw - 965px ) / 2);
  }
}

#block-system-main-menu ul li {
  list-style: none;
  display: inline;
  float: none;
}

#block-system-main-menu ul li a {
  font-size: 120%;
  font-weight: 700;
  margin: 0 0 0 15px;
  text-decoration: none;
  text-transform: uppercase;
  border-color: #1f2131;
  border-bottom: 3px solid transparent;
  padding-bottom: 5px;
  display: inline-block;
}

#block-system-main-menu ul li a, #block-system-main-menu ul li a:visited {
  color: #fff;
}

#block-system-main-menu ul li a:hover, #block-system-main-menu ul li a:active, #block-system-main-menu ul li a.is-active-trail, #block-system-main-menu ul li a.active-trail, #block-system-main-menu ul li a.active {
  border-bottom: 3px solid #dd3a31;
  padding-bottom: 5px;
}

#block-system-main-menu ul li.first a {
  margin-left: 0;
}

body.page-locations h1 {
  margin-bottom: 1.5rem;
}

.view-id-locations {
  font-family: "Roboto", "Franklin Gothic Medium", Tahoma, Arial, sans-serif;
  margin: 0 0 4rem;
}

.view-id-locations .views-field-title-1 a {
  height: 1px;
  color: white;
  font-size: 1px;
}

ul.quicktabs-tabs {
  font-weight: 500;
  padding: 0;
  text-transform: uppercase;
  font-size: .9rem;
  letter-spacing: 0;
}

@media only screen and (min-width: 730px) {
  ul.quicktabs-tabs {
    font-size: 1.1rem;
    letter-spacing: 1px;
    border-bottom: 3px solid #dd3a31;
  }
}

ul.quicktabs-tabs li {
  background: #fdece4;
  padding: 0.5rem 1rem;
  display: inline-block;
  margin-right: -5px;
  letter-spacing: 1px;
  width: 100%;
  margin-bottom: .5rem;
}

@media only screen and (min-width: 730px) {
  ul.quicktabs-tabs li {
    padding: 0.5rem 2rem;
    width: auto;
    margin-bottom: 0;
  }
}

ul.quicktabs-tabs li:hover {
  background-color: #dd3a31;
}

ul.quicktabs-tabs li:hover a {
  color: #fff;
}

ul.quicktabs-tabs li.active {
  background: #dd3a31;
}

ul.quicktabs-tabs li.active a {
  color: #fff;
}

ul.quicktabs-tabs a {
  color: inherit;
  text-decoration: none;
}

.node-location.view-mode-full {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media only screen and (min-width: 985px) {
  .node-location.view-mode-full {
    flex-wrap: nowrap;
  }
}

.rs-location__header {
  flex: 1 1 200px;
  margin-bottom: 3rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media only screen and (min-width: 985px) {
  .rs-location__header {
    display: block;
    margin-right: 3rem;
  }
}

.field-name-field-image-map {
  flex: 1 1 auto;
}

.field-name-field-image-map .field-items picture:not(:last-of-type) {
  margin-bottom: 4rem;
  display: block;
}

.rs-location__address-phone-wrapper {
  flex: 1 1 33%;
  text-align: center;
  min-width: 200px;
}

@media only screen and (min-width: 985px) {
  .rs-location__address-phone-wrapper {
    text-align: left;
  }
}

.rs-location__maps-video-wrapper {
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 66%;
  justify-content: space-around;
}

@media only screen and (min-width: 985px) {
  .rs-location__maps-video-wrapper {
    width: 150px;
    display: block;
  }
}

.country {
  display: none;
}

.organisation-name {
  font-weight: 700;
  margin-bottom: 0.5rem;
  color: #dd3a31;
  display: block;
}

.field-name-field-address .locality-block {
  display: block;
}

.field-name-field-phone {
  margin-bottom: 1rem;
}

.rs-location__gmap-link {
  margin-bottom: 2rem;
  order: 2;
  margin-top: 107px;
}

@media only screen and (min-width: 985px) {
  .rs-location__gmap-link {
    margin-top: 0;
  }
}

.rs-location__gmap-link a {
  color: #fff;
  background-color: #1f2131;
  border: 1px solid #1f2131;
  font-weight: 500;
  text-transform: uppercase;
  width: 100%;
  border: 2px solid transparent;
}

.rs-location__gmap-link a:hover, .rs-location__gmap-link a:focus {
  color: #fff;
  background-color: #333650;
}

.rs-location__gmap-link a:hover {
  color: #fff !important;
  border: 2px solid #dd3a31;
  background-color: #333650;
}

.rs-location__getting-there {
  order: 1;
}

.field-name-field-getting-there-video .field-item {
  text-align: center;
}

.rs-getting-there__image {
  display: inline-block;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;
  width: 85px;
  height: 85px;
  margin-bottom: 1rem;
}

.rs-getting-there__button a {
  color: #fff;
  background-color: #1f2131;
  border: 1px solid #1f2131;
  font-weight: 500;
  text-transform: uppercase;
  width: 100%;
  border: 2px solid transparent;
}

.rs-getting-there__button a:hover, .rs-getting-there__button a:focus {
  color: #fff;
  background-color: #333650;
}

.rs-getting-there__button a:after {
  display: none;
}

.rs-getting-there__button a:hover {
  color: #fff !important;
  border: 2px solid #dd3a31;
  background-color: #333650;
}

body.page-node-80 footer {
  margin-top: 0;
}

.section-treatments .field-type-text-with-summary {
  font-family: "Roboto", "Franklin Gothic Medium", Tahoma, Arial, sans-serif;
  font-size: 120%;
}

.section-treatments h1 {
  margin: 0;
}

.region-services {
  margin: 1rem 0 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  text-align: center;
}

@media only screen and (min-width: 730px) {
  .region-services {
    text-align: left;
    margin: 4rem 0 0;
  }
}

.rs-services__medical_wrapper {
  margin: 0 0 0.5rem;
}

.rs-services__medical_title_wrapper {
  background-color: #e63a2e;
  padding: 2.5px 1rem;
  width: 200px;
}

.rs-services__medical_title_wrapper a {
  color: #fff;
  text-decoration: none;
}

.rs-services__medical_title_wrapper h3 {
  margin: 0;
}

.rs-services__medical_title_wrapper:hover, .rs-services__medical_title_wrapper.rs-services__hover {
  background-color: #a50b01;
}

.rs-services__medical_list_wrapper {
  font-size: 100%;
  font-family: "Roboto", "Franklin Gothic Medium", Tahoma, Arial, sans-serif;
  background-color: #fbb49e;
  width: 185px;
  padding: 1rem 2rem;
  position: relative;
  left: 15px;
  transition: none;
}

.rs-services__medical_list_wrapper a {
  text-decoration: none;
  color: inherit;
  padding: 5px 0;
  display: block;
}

.rs-services__medical_list_wrapper a:hover {
  color: #a50b01;
}

#block-views-medical-conditions-block .block__title,
#block-rs-blocks-body .block__title,
#block-views-treatments-expertise-block .block__title {
  text-transform: uppercase;
  color: #55565a;
  white-space: nowrap;
}

#block-views-medical-conditions-block {
  flex: 1 1 30%;
  margin-bottom: 2rem;
}

@media only screen and (min-width: 730px) {
  #block-views-medical-conditions-block .block__title {
    padding-left: 1rem;
  }
}

@media only screen and (min-width: 730px) {
  #block-views-medical-conditions-block {
    text-align: right;
    width: 30%;
  }
}

#block-views-medical-conditions-block .view-medical-conditions {
  display: inline-block;
}

@media only screen and (min-width: 730px) {
  #block-views-medical-conditions-block .view-content {
    float: right;
  }
}

#block-rs-blocks-body {
  flex: 1 1 35%;
  display: none;
}

@media only screen and (min-width: 730px) {
  #block-rs-blocks-body {
    display: block;
  }
}

#block-views-treatments-expertise-block {
  flex: 1 1 35%;
}

.rs-services__body-wrapper > div {
  margin: 0 auto;
}

.view-treatments-expertise {
  display: inline-block;
}

.view-treatments-expertise a {
  color: #1f2131;
  background-color: #e8e8ea;
  text-decoration: none;
  font-family: "Roboto", "Franklin Gothic Medium", Tahoma, Arial, sans-serif;
  font-size: 1rem;
  line-height: 1.75rem;
  margin: 0 0 0.5rem;
  padding: 2.5px 1rem;
  display: block;
  max-width: 260px;
}

.view-treatments-expertise a:hover {
  background-color: #1f2131;
  color: #fff;
}

#block-views-services-videos-block {
  clear: both;
  background-color: #fdece4;
  margin-left: calc(50% - 50vw);
  width: 100vw;
  padding: 2rem 0;
  margin-top: 3rem;
}

#block-views-services-videos-block .services-videos-block__inner-wrapper {
  margin: auto;
  max-width: 100vw;
  padding: 0 2.5rem;
}

@media only screen and (min-width: 985px) {
  #block-views-services-videos-block .services-videos-block__inner-wrapper {
    max-width: 965px;
    padding: 0;
  }
}

#block-views-services-videos-block .block__title {
  font-size: 2.1rem;
  margin: 0;
  color: #dd3a31;
}

#block-views-services-videos-block .view-header {
  font-family: "Roboto", "Franklin Gothic Medium", Tahoma, Arial, sans-serif;
  color: #55565a;
  font-size: 1.1rem;
}

.view-services-videos .views-field-title {
  display: none;
}

@media only screen and (min-width: 480px) {
  .view-services-videos .view-content {
    margin-left: -10px;
    margin-right: -10px;
  }
  .view-services-videos .view-content:after {
    content: '';
    display: table;
    clear: both;
  }
}

@media only screen and (min-width: 480px) {
  .view-services-videos .views-row {
    padding-left: 10px;
    padding-right: 10px;
    width: 50%;
    float: left;
  }
  .view-services-videos .views-row:nth-of-type(2n + 1) {
    clear: both;
  }
}

@media only screen and (min-width: 730px) {
  .view-services-videos .views-row {
    width: 33%;
  }
  .view-services-videos .views-row:nth-of-type(2n + 1) {
    clear: none;
  }
  .view-services-videos .views-row:nth-of-type(3n + 1) {
    clear: both;
  }
}

@media only screen and (min-width: 985px) {
  .view-services-videos .views-row {
    width: 20%;
  }
  .view-services-videos .views-row:nth-of-type(3n + 1) {
    clear: none;
  }
  .view-services-videos .views-row:nth-of-type(5n + 1) {
    clear: both;
  }
}

@media only screen and (min-width: 730px) {
  #webform-client-form-6 {
    width: 50%;
    float: left;
    padding-left: 10px;
    padding-right: 10px;
    padding-left: 0;
  }
}

#webform-client-form-6 .form-item:first-of-type {
  margin-top: 0;
}

#webform-client-form-6 .form-actions {
  overflow: hidden;
}

#webform-client-form-6 input[type='submit'] {
  float: right;
}

@media only screen and (min-width: 730px) {
  #webform-client-form-6 + .field-name-field-image-multi, .rs-form__webform-region {
    width: 50%;
    float: right;
    padding-left: 10px;
    padding-right: 10px;
    padding-right: 0;
  }
}

.rs-form__webform-region {
  clear: both;
  margin-bottom: 20px;
}

#webform-client-form-6 + .field-name-field-image-multi {
  margin: 1rem 0 0;
}

@media only screen and (min-width: 730px) {
  #webform-client-form-6 + .field-name-field-image-multi {
    margin: 0;
  }
}

.rs-youtube-video-background__video-wrapper {
  position: absolute;
  left: 0;
  width: 100vw;
  height: 33vw;
}

.rs-youtube-video-background__video-positioning-wrapper {
  position: relative;
  width: 100vw;
  height: 33vw;
  overflow: hidden;
  padding-top: 0;
}

.rs-youtube-video-background__video-spacer {
  width: 100vw;
  height: 33vw;
  margin-bottom: 25px;
}

.layout-swap {
  position: static;
}

body {
  position: relative;
}

body.front .contextual-links-region {
  position: static;
}

.rs-youtube-video-background__image {
  position: absolute;
  z-index: 1;
  top: 0;
  width: 100%;
}

.rs-youtube-video-background__image img {
  max-width: 100% !important;
  width: 100% !important;
}

.rs-youtube-video-background__video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 100vw !important;
  height: 300% !important;
}

.rs-youtube-video-background__booking,
.rs-youtube-video-background__tagline {
  z-index: 2;
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
}

.rs-youtube-video-background__booking {
  color: #fff;
  background-color: #dd3a31;
  border: 1px solid #dd3a31;
  font-weight: 500;
  text-transform: uppercase;
  text-transform: uppercase;
  max-width: 450px;
  margin: 0 auto;
  bottom: 0;
  border: 2px solid transparent;
  font-size: 1.15rem;
  letter-spacing: 1px;
  font-weight: 500;
}

.rs-youtube-video-background__booking:hover, .rs-youtube-video-background__booking:focus {
  color: #fff;
  background-color: #e4645d;
}

.rs-youtube-video-background__booking:hover {
  color: #fff !important;
  border: 2px solid #fff;
  background-color: #0c8400;
}

.rs-youtube-video-background__booking a {
  font-size: 80%;
  color: currentColor;
  text-decoration: none;
}

.rs-youtube-video-background__booking a:before {
  content: '\e911';
  font-family: "icomoon3";
  font-size: 150%;
  vertical-align: sub;
}

@media only screen and (min-width: 480px) {
  .rs-youtube-video-background__booking {
    font-size: 130%;
    padding: .6em 1em;
    bottom: 40px;
  }
}

.rs-youtube-video-background__tagline {
  color: #fff;
  line-height: 1;
}

@media only screen and (min-width: 480px) {
  .rs-youtube-video-background__tagline {
    bottom: 100px;
  }
}

@media only screen and (min-width: 730px) {
  .rs-youtube-video-background__tagline {
    font-size: 280%;
  }
}

.rs-header--header-wrapper {
  position: relative;
  min-height: 120px;
}

.rs-header--header-wrapper img {
  vertical-align: middle;
}

@media only screen and (min-width: 730px) {
  .rs-header--header-wrapper {
    width: 66.66%;
    float: left;
    padding-left: 10px;
    padding-right: 10px;
    padding-left: 0;
  }
}

.rs-title--services {
  background-color: rgba(255, 255, 255, 0.6);
  line-height: 1.2;
  padding: 1rem 1.5rem;
  margin: 0;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  hyphens: auto;
  word-wrap: break-word;
  font-size: 1.9rem;
  color: #1f2131;
}

@media only screen and (min-width: 730px) {
  .rs-title--services {
    font-size: 2.2rem;
  }
}

.rs-title--services a {
  color: inherit;
  text-decoration: none;
}

.node-service .field-type-text-with-summary {
  font-family: "Roboto", "Franklin Gothic Medium", Tahoma, Arial, sans-serif;
  padding: 2rem 0 0;
  clear: both;
}

@media only screen and (min-width: 730px) {
  .node-service .field-type-text-with-summary {
    width: 66.66%;
  }
}

.field-name-field-medical-conditions {
  margin: 3rem 0 1.5rem;
}

.field-name-field-medical-conditions .field-label {
  font-weight: 700;
  font-family: "Roboto", "Franklin Gothic Medium", Tahoma, Arial, sans-serif;
  font-size: 100%;
  text-transform: uppercase;
  line-height: 1;
  margin-bottom: 1rem;
}

.field-name-field-medical-conditions .field-items {
  background-color: #f3f3f4;
  padding: 1.5rem;
  font-family: "Roboto", "Franklin Gothic Medium", Tahoma, Arial, sans-serif;
  font-size: 80%;
}

@media only screen and (min-width: 985px) {
  .field-name-field-medical-conditions .field-items {
    font-size: 110%;
  }
}

.field-name-field-medical-conditions .field-items a {
  font-weight: 700;
  text-decoration: none;
}

.field-name-field-medical-conditions .field-items .hierarchical-select-item-separator {
  padding: 0 0.5rem;
}

.field-name-field-medical-conditions .field-items .field-item {
  margin: 0 0 1rem;
}

.field-name-field-medical-conditions .field-items .field-item:last-of-type {
  margin: 0;
}

@media only screen and (min-width: 730px) {
  .field-name-field-medical-conditions {
    width: 33.33%;
    float: right;
    padding-left: 10px;
    padding-right: 10px;
    padding-right: 0;
    margin-top: 0;
  }
}

#block-views-services-videos-block-1 {
  margin: 1rem 0 0;
}

#block-views-services-videos-block-1 .view-header {
  font-family: "Roboto", "Franklin Gothic Medium", Tahoma, Arial, sans-serif;
}

#block-views-services-videos-block-1 .block__title {
  font-size: 100%;
  text-transform: uppercase;
  line-height: 1;
  margin-bottom: 1rem;
}

.taxonomy-term.vocabulary-medical-conditions > h2 {
  display: none;
}

.field-name-field-image img {
  width: auto;
}

.rs-header--wrapper {
  position: relative;
  min-height: 120px;
}

.rs-header--wrapper img {
  vertical-align: middle;
}

.rs-header--wrapper .rs-title {
  background-color: rgba(255, 255, 255, 0.6);
  font-size: 1.9rem;
  line-height: 1.2;
  padding: 1rem 1.5rem;
  margin: 0;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  color: #1f2131;
  hyphens: auto;
}

@media only screen and (min-width: 730px) {
  .rs-header--wrapper .rs-title {
    font-size: 2.2rem;
  }
}

#taxonomy-term-22 .taxonomy-term-description {
  font-family: "Roboto", "Franklin Gothic Medium", Tahoma, Arial, sans-serif;
  font-size: 110%;
  margin: 1rem 0 0;
}

.view-id-taxonomy_term .node-blog {
  margin-bottom: 2.5rem;
}

@media only screen and (min-width: 730px) {
  .view-id-taxonomy_term .node-blog {
    display: flex;
    margin-left: -10px;
    margin-right: -10px;
  }
  .view-id-taxonomy_term .node-blog:after {
    content: '';
    display: table;
    clear: both;
  }
}

@media only screen and (min-width: 730px) {
  .view-id-taxonomy_term .node-blog .group-left {
    float: left;
    padding-left: 10px;
    padding-right: 10px;
    flex: 1;
  }
}

@media only screen and (min-width: 730px) {
  .view-id-taxonomy_term .node-blog .group-right {
    float: left;
    padding-left: 10px;
    padding-right: 10px;
    flex: 2;
  }
}

.view-id-taxonomy_term .node-blog .more-link {
  font-weight: 700;
  font-family: "Roboto", "Franklin Gothic Medium", Tahoma, Arial, sans-serif;
  text-decoration: none;
  padding: 0 0 0 0.5rem;
}

.view-id-taxonomy_term .node-blog .more-link:after {
  content: '>';
  padding: 0 0 0 0.5rem;
}

@media only screen and (min-width: 730px) {
  .view-id-taxonomy_term {
    width: 66.66%;
    float: left;
    padding-left: 10px;
    padding-right: 10px;
    padding-left: 0;
  }
}

.view-taxonomy-term-fully-rendered {
  padding-bottom: 1.5rem;
}

#block-views-51d05e753334a7feb28f343128403d94 {
  margin: 3rem 0 0;
}

#block-views-51d05e753334a7feb28f343128403d94 .block__title {
  font-size: 100%;
  text-transform: uppercase;
  line-height: 1;
  margin-bottom: 1rem;
}

#block-views-51d05e753334a7feb28f343128403d94 .view-related-treatments-services {
  background-color: #f3f3f4;
  padding: 1.5rem;
}

#block-views-51d05e753334a7feb28f343128403d94 .view-related-treatments-services .views-row {
  font-family: "Roboto", "Franklin Gothic Medium", Tahoma, Arial, sans-serif;
  font-size: 110%;
  padding: 0 0 0.5rem;
}

#block-views-51d05e753334a7feb28f343128403d94 .view-related-treatments-services .views-row a {
  font-weight: 700;
  text-decoration: none;
}

@media only screen and (min-width: 730px) {
  #block-views-51d05e753334a7feb28f343128403d94 {
    width: 33.33%;
    float: right;
    padding-left: 10px;
    padding-right: 10px;
    padding-right: 0;
    margin-top: 0;
  }
}

.view-id-videos .node-teaser {
  margin-bottom: 2.5rem;
}

@media only screen and (min-width: 730px) {
  .view-id-videos .node-teaser {
    display: flex;
    margin-left: -10px;
    margin-right: -10px;
  }
  .view-id-videos .node-teaser:after {
    content: '';
    display: table;
    clear: both;
  }
}

@media only screen and (min-width: 730px) {
  .view-id-videos .node-teaser .group-left {
    float: left;
    padding-left: 10px;
    padding-right: 10px;
    flex: 1;
  }
}

@media only screen and (min-width: 730px) {
  .view-id-videos .node-teaser .group-right {
    float: left;
    padding-left: 10px;
    padding-right: 10px;
    flex: 3;
  }
}

.view-id-videos .pager-last, .view-id-videos .pager-first {
  display: none;
}

.colorbox-load {
  display: inline-block;
  position: relative;
}

.colorbox-load img {
  vertical-align: middle;
}

.colorbox-load:after {
  content: '\f04b';
  font-family: "icomoon3";
  font-size: 20px;
  color: #dd3a31;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  padding: 9px 15px;
  transition: all .2s ease-in;
}

.colorbox-load:hover:after {
  color: #000;
  background-color: rgba(255, 255, 255, 0.6);
}

#cboxClose,
#colorbox {
  transition: none;
}

.rs-little-play {
  position: relative;
}

.rs-little-play__inner a {
  right: -2px;
  left: auto;
  transform: translate(-50%, -50%);
  display: block;
  position: absolute;
  top: 50%;
}

.rs-little-play__inner a:after {
  padding: 5px 10px;
  font-size: 13px;
  color: #fff;
  background-color: #dd3a31;
}

.view-store .view-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.view-store .view-content article {
  flex: 1 1 305px;
  margin: .5rem;
  background: #f4f4f4;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  align-content: stretch;
}

.view-store .view-content .field-name-field-image .field-item {
  display: flex;
}

.view-store .view-content .field-name-field-image .field-item img {
  flex: 0 1 100%;
}

.view-store .view-content .rs-product__title {
  font-size: 1.25rem;
  line-height: 1.65rem;
  font-weight: 500;
  margin: 1rem 0;
}

.view-store .view-content .rs-product__body {
  flex: 1 0;
}

.view-store .view-content .rs-product__body .field-items {
  text-align: left;
}

.view-store .view-content .rs-product__content-wrapper {
  flex: 1;
  padding: 0 .75rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.view-store .view-content .rs-product__cart {
  margin-right: .5rem;
}

.view-store .view-content .links.inline {
  margin: 1rem 0;
  padding: 0;
  font-weight: bold;
}

.view-store .view-content .links.inline li {
  text-align: center;
  display: block;
  text-transform: uppercase;
}

.rs-product__out-of-stock .node-readmore {
  font-size: 2rem;
  font-weight: bold;
  opacity: 0;
}

.rs-product__out-of-stock:hover .field-name-field-image,
.rs-product__out-of-stock:hover .rs-product__body,
.rs-product__out-of-stock:hover .rs-product__title {
  opacity: 0.3;
}

.rs-product__out-of-stock:hover .node-readmore {
  opacity: 1;
}

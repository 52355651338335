// Footer
//
// Footer Styling
//
// Markup: footer.twig
//
// Style guide: rosept.footer

.footer {
  @extend %full-width-window;
  color: $white;
  font-family: $font-family-1;
  background-color: $color-brand-red;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 8% auto;
  margin-top: 2em;

  a {
    color: $white;

		&:hover {
			color: $color-steel-black;
		}
	}

	a:not(.link-underline) {
		text-decoration: none;
  }

  .menu {
    font-size: $font-sm;
    font-weight: $font-weight-bold;
    padding: 0;
  }

  #block-multiblock-1 h2 {
    display: none;
  }

  .menu__item {
    list-style: none;
    font-size: $font-lg;
    margin: 0 0 $spacing;

    &.first {
      display: none;
    }

    a {
      text-transform: uppercase;
      font-size: $font-lg-xx;

      &:active,
      &:hover {
        color: $color-steel-black;
      }
    }
  }
}

// ends footer


.footer__inner-wrapper {
  max-width: $site-max-width;
  width: 100%;
  padding: $spacing-2 $spacing 0 $spacing-2;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @include media-lg {
    padding: $spacing-2 0 0 0;
  }
}

.footer__logo {
  display: inline-block;
  vertical-align: middle;

  img {
    max-width: 65px !important;
  }
}

.footer__name-and-slogan {
  @extend %logo__name-and-slogan;
  vertical-align: middle;

  @include media-mid {
    //background-size: 80%;
  }

  @include media-lg {
    //background-size: 60%;
  }
}

.footer__site-link {
  @extend %logo__site-link;
}

#block-block-17 { // Logo block
  margin-right: $spacing;
  flex: 0 1 auto;
  margin-bottom: $spacing;
}

#block-multiblock-1 {
  flex: 0 1 auto;
  margin-right: $spacing;
  margin-bottom: $spacing;

  .rs-header-contact__social {
    color: $color-steel-black;

    &:hover {
      color: $white;
    }
  }

  .rs-header-contact__social-fb {
    margin: 0;
  }

  rs-header-contact__social-wrapper {
    position: relative;
    right: 4px; // account for FB icon spacing
  }

  ul.menu {
    margin: 0;
  }

  @include media-mid {
    padding-left: 0;
  }
  // since we cannot set a row mixin on parent which uses fullwidth hack
}

#block-block-15 { // Booking and contact info
  flex: 2 2 100%;

  @include media-lg {
    flex: 2 2 calc(50% - 4rem);
    padding-right: 0;
    margin-left: 4rem;
  }
  // since we cannot set a row mixin on parent which uses fullwidth hack
}

#block-block-16 {
  clear: both;
}

// Footer: Logo

.logo-block--footer {
  color: $white;
}

.logo__site-name--footer {
  font-size: 180%;
}

// Footer: Book Appt
.rs-footer-contact__booking {
  float: left;
  width: 100%;
}

.rs-footer-contact__button {
  @extend %full-width;
  background-color: $color-steel-black;
  border: 2px solid transparent;
  margin-bottom: $spacing;
  white-space: nowrap;
  font-size: .9rem;

  @include media-mid {
    font-size: 130%;
  }

  &:hover {
    color: $white !important;
    border: 2px solid $white;
    background-color: lighten($color-steel-black, 10%);
  }
}

.rs-footer-contact__contact {
  float: left;
  width: 100%;
}

.rs-footer-contact__contact-addresses {
  @extend %clearfix;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .rs-footer-contact__info {
    margin-bottom: $spacing-2;
  }

  .rs-footer-contact__info-left {
    flex: 1 1 auto;
    margin-right: $spacing;
  }

  .rs-footer-contact__info-right {
    flex: 0 1 auto;
  }
}

.rs-footer-contact__contact-phones {
  margin-bottom: $spacing-2;
}

// Footer: Privacy Policy
#block-block-16 { // Privacy block
  width: 100%;
  flex: 4 0 100%;
}

.rs-footer-privacy {
  @extend %full-width-window;
  font-size: $font-sm;
  background-color: $color-steel-black;
  padding: $spacing;
  clear: both;

  a {
    text-align: center;

    &:hover {
      color: $color-brand-red;
    }
  }
  .rs-footer-privacy__inner-wrapper {
    max-width: 100vw;
    margin: auto;
    padding: 0 2rem;

    @include media-lg {
      max-width: $site-max-width;
      padding: 0;
    }
  }
}

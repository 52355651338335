// Typography
//
// Typography and Fonts 
//
// Markup: type.twig
//
// Style guide: rosept.type
// Add Brick.im fonts here.
@import url('https://brick.a.ssl.fastly.net/Raleway:400,700/Lora:400,700i');

p {
  margin: 0 0 1rem;
}

h1, %h1, h2, %h2, h3, %h3, h4, %h4, h5, %h5, h6, %h6 {
  margin-top: 0;
}

// Font Sizes: Outputs classes & placeholders from variables file such as:
// font-sm, font-sm-x, font-sm-xx, font-lg, font-lg-x, font-lg-xx

$font-sizes-site: (
        font-sm: $font-sm,
        font-sm-x: $font-sm-x,
        font-sm-xx: $font-sm-xx,
        font-lg: $font-lg,
        font-lg-x: $font-lg-x,
        font-lg-xx: $font-lg-xx
);

@each $font-size, $font-sizing in $font-sizes-site {
  .#{$font-size}, %#{$font-size} {
    font-size: $font-sizing;
  }
}

// Fonts: Helpers

// Raleway font
.font-family-1 {
  font-family: $font-family-1;
}

// Lora font
.font-family-2 {
  font-family: $font-family-2;
}

.font-uppercase {
  text-transform: uppercase;
}

.font-bold {
  font-weight: $font-weight-bold;
}

// Typography: Links

.link-underline, a.link-underline {
  text-decoration: underline;
}

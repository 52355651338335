// Grid
//
// Grid: Mixins
//
// Markup: grid.twig
//
// Style guide: rosept.grid

// Grid: Media Queries

// Media Query: Large

@mixin media-lg {
  @media only screen and (min-width: $media-lg) {
    @content;
  }
}

@mixin media-xl {
  @media only screen and (min-width: $media-xl) {
    @content;
  }
}

@mixin media-mid-lg {
  @media only screen and (min-width: $media-mid-lg) {
    @content;
  }
}

// Media Query: Mid/Medium

@mixin media-mid {
  @media only screen and (min-width: $media-mid) {
    @content;
  }
}

// Media Query: Small

@mixin media-sm {
  @media only screen and (min-width: $media-sm) {
    @content;
  }
}

// Gutters: Default

@mixin gutter {
  padding-left: $gutter;
  padding-right: $gutter;
}

.gutter, %gutter {
  @include gutter;
}

// Mixin: Container

/*
  - Wrap a row in a container, using a mixin, placeholder or class
  - All parameters have defaults set
  - Parameters:
      - $max-width {number} : defaults to $site-width variable value;
      - $centered {boolean} : defaults to true;
      - $gutter {number} : defaults to $gutter variable value;
*/

@mixin container($max-width: $media-lg, $centered: true, $gutter: $gutter) {
  @if $max-width {
    max-width: $max-width;
  }

  @if $centered {
    margin-left: auto;
    margin-right: auto;
  }

  @if $gutter {
    padding-left: $gutter;
    padding-right: $gutter;
  }
}

.container, %container, .layout-center {
  @include container;
}

// Mixin: Row

/*
  - Used to wrap grid columns
  - Must be wrapped in a container
  - All parameters have defaults set
  - Parameters:
      - $clearfix {boolean} : defaults to true, optionally set to false for inline-block or flex grids
      - $flex {boolean}: defaults to false;
      - $gutter {number}: defaults to $gutter variable value
*/

@mixin row($clearfix: true, $flex: false, $gutter: $gutter) {

  @if $clearfix {
    @include clearfix;
  }

  @if $flex {
    display: flex;
  }

  @if $gutter {
    margin-left: -$gutter;
    margin-right: -$gutter;
  }
}

.row, %row {
  @include row;
}

/*-------------------------------------*/
/* MIXIN: COLUMN */
/*
  - Wrap in a row, which resets first and last column's left and right gutters, respectively
  - All parameters have defaults set
  - Parameters:
      $width {number} : defaults to false, i.e. width not set;
      $float {string} : defaults to left. Optionally set to right, none or false
      $gutter {number} : defaults to $gutter variable value;
*/

@mixin col($width: false, $float: left, $inline: false, $flex: false, $gutter: $gutter, $spacing-v: false) {

  @if $width {
    width: $width;
  }

  @if $float == left {
    float: left;
  }
  @else if $float == right {
    float: right;
  }
  @else if $float == none {
    float: none;
  }

  @if $inline {
    display: inline-block;
  }

  @if $flex {
    display: flex;
  }

  @if $gutter {
    padding-left: $gutter;
    padding-right: $gutter;
  }

  @if $spacing-v {
    padding-top: $spacing;
    padding-bottom: $spacing;
  }
}

// Page: Service
//
// Service styles
//
// Markup: service.twig
//
// Style guide: rosept.service

// Page: Service: Banner Image

.rs-header--header-wrapper {
  position: relative;
  min-height: 120px;

  img {
    vertical-align: middle;
  }

  @include media-mid {
    @include col(66.66%);
    padding-left: 0;
  }
}

.rs-title--services {
  background-color: rgba($white, .6);
  line-height: 1.2;
  padding: $spacing ($spacing * 1.5);
  margin: 0;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  hyphens: auto;
  word-wrap: break-word;
  font-size: 1.9rem;
  color: $color-steel-black;

  @include media-mid {
    font-size: 2.2rem;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
}

// Page: Service: Summary: Banner

.node-service {
  .field-type-text-with-summary {
    font-family: $font-family-1;
    padding: $spacing-2 0 0;
    clear: both;

    @include media-mid {
      width: 66.66%;
    }
  }
}

// Page: Service: Related Conditions

.field-name-field-medical-conditions {
  margin: $spacing-3 0 ($spacing * 1.5);

  .field-label {
    font-weight: $font-weight-bold;
    font-family: $font-family-1;
    font-size: 100%;
    text-transform: uppercase;
    line-height: 1;
    margin-bottom: $spacing;
  }

  .field-items {
    background-color: $grey-mist;
    padding: ($spacing * 1.5);
    font-family: $font-family-1;
    font-size: $font-sm-x;

    @include media-lg {
      font-size: $font-lg;
    }

    a {
      font-weight: $font-weight-bold;
      text-decoration: none;
    }

    .hierarchical-select-item-separator {
      padding: 0 $spacing-half;
    }

    .field-item {
      margin: 0 0 $spacing;

      &:last-of-type {
        margin: 0;
      }
    }
  }

  @include media-mid {
    @include col(33.33%, $float: right);
    padding-right: 0;
    margin-top: 0;
  }
}

// ends field-name-field-medical-conditions

// Page: Services: Videos

#block-views-services-videos-block-1 {
  margin: $spacing 0 0;

  .view-header {
    font-family: $font-family-1;
  }

  .block__title {
    font-size: 100%;
    text-transform: uppercase;
    line-height: 1;
    margin-bottom: $spacing;
  }
}

// Page: Home
//
// Home page styles
//
// Markup: home.twig
//
// Style guide: rosept.home

.region-homepage-top {
  clear: both;
}

body.front .layout-swap {
  padding-top: 0;
}

// Page: Home: Services

.region-homepage-middle {
  @include media-sm {
    @include clearfix;
  }
}

.rs-home-icons__wrapper {
  z-index: 1;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.rs-home-icons__icon-wrapper {
  flex: 1 1 225px;
  text-align: center;
}

.rs-home-icons__link {
  display: inline-block;
  padding: $spacing $spacing-3;

  &:hover {
    background-color: $color-light-salmon;

    .rs-home-icons__hr {
      border-color: $color-brand-red;
    }
  }
}

.rs-home-icons__icon {
  width: 100px;
  height: 100px;
  margin: auto;
  display: block;
  background: no-repeat center/contain url('../images/home-icons__services.svg');

  &-services {
    background-image: url('../images/home-icons__services.svg');
  }

  &-videos {
    background-image: url('../images/home-icons__videos.svg');
  }

  &-team {
    background-image: url('../images/home-icons__team.svg');
  }

  &-blog {
    background-image: url('../images/home-icons__blog.svg');
  }
}

.rs-home-icons__title {
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  font-size: 1.15rem;
  font-weight: 700;
  text-align: center;
  white-space: nowrap;
}

.rs-home-icons__hr {
  display: block;
  height: 1px;
  width: 45px;
  border-top: 7px solid $color-steel-black;
  margin: 1rem auto 0.25rem auto;
}

// Page: Home: Insurance
.rs-homepage__insurance {
  @extend %full-width-window;
  font-family: $font-family-1;
  font-weight: 300;
  color: $color-text-gray;
  font-size: 220%;
  line-height: 1.1;
  text-align: center;
  margin-top: ($spacing * 4);
  margin-bottom: ($spacing * 4);
  clear: both;
}

// Page: Home: Pitch

.region-homepage-bottom {
  @include media-sm {
    @include clearfix;
  }
}

.rs-homepage__pitch-wrapper, .rs-homepage__map {
  font-family: $font-family-1;

}

#block-block-13, #block-block-14 {
  padding-left: ($spacing * 1.5);
  padding-right: ($spacing * 1.5);

  @include media-mid {
    @include col(50%, $gutter: $spacing-3);
  }
}

#block-block-13 {

  @include media-lg {
    padding-left: 0;
  }
}

#block-block-14 {
  margin: $spacing-3 0 0;

  @include media-mid {
    margin: 0;
  }

  @include media-lg {
    padding-right: 0;
  }
}

.rs-homepage__pitch {
  font-size: $font-lg-xx;
  line-height: 1.5;
  font-weight: 300;
  color: $color-text-gray;
  text-align: justify;
  hyphens: auto;
}

.rs-homepage__booking {
  @include button($color-brand-green);
  margin: ($spacing + 4) 0 0;
  width: 100%;

  a {
    color: currentColor;
    text-decoration: none;
    display: inline-block;

    &:before {

      @extend %icon-left-lg;
      content: '\e911';
      font-family: $font-icon;
      font-size: 150%;
      vertical-align: sub;
    }
  }

  @include media-mid {
    @include button--lg-xx;
  }
}


.rs-homepage__pitch-heading {
  font-size: 180%;
  font-weight: 700;
  line-height: 1.3;
  color: $color-brand-red;
}

.rs-homepage__map {
  position: relative;

  img {
    display: block;
    margin: 0 auto;

    @include media-mid {
      margin: 0;
    }
  }
}


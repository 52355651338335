// Global
//
// Globals Styling
//
// Markup: global.twig
//
// Style guide: rosept.global

// Code Blocks

.code-block {
  font-family: monospace;
  background: $grey-cloud;
  display: block;
  margin: 0 0 1rem;
}

// Logo: Block with Tagline

.logo-block__link {
  display: inline-block;
}

.logo__name-and-slogan, %logo__name-and-slogan {
  text-transform: uppercase;
  display: inline-block;
  vertical-align: top;
}

.logo__site-name, %logo__site-name {
  font-size: 220%;
  line-height: 1.1;
  font-weight: $font-weight-normal;
  margin: 0 0 0 $spacing;
}

.logo__site-link, %logo__site-link {
  color: inherit;
  text-decoration: none;
}

html .layout-3col {
  margin-left: 0;
  margin-right: 0;
}

.gn-menu-main {
  width: 60px;
}

* {
  transition: color, background-color, border, .3s linear;
}

// Links

a {
  color: $color-link;
  text-decoration: none;

  &:hover,
  &:active,
  &.is-active-trail,
  &.active-trail,
  &.active {
    color: $color-link-hover;
  }
}


// Related links: Parent Child

.lineage-item-level-0 {
  a {
    color: $color-brand-red--dark;

    &:hover {
      color: $color-link;
    }
  }
}

.field-name-body {
  font-size: 120%;
  line-height: 1.65em;
}

.csshyphens .field-name-body {
  text-align: justify;
  hyphens: auto;
}

// Hide flagging from non #1 users. This is because flags added by other users
// cannot be seen by current user. To my dismay. This is a workaround.

body:not(.userid-1) .links.inline .flag {
  display: none;
}

iframe {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.field-collection-view {
  border: none;
}

.field-collection-container {
  margin: 0;
  padding: 0;
  border: none;
}

// Page: Blog
//
// Blog Post
//
// Markup: blog.twig
//
// Style guide: rosept.blog

.node-type-blog {

  .node-blog {
    @include media-mid {
      @include gutter;
      float: left;
      width: calc(100% - 160px);
    }
  }

  header {
    margin-bottom: $spacing;
  }

  .layout-3col {
    margin-left: 0;
    margin-right: 0;
    padding-top: 0;

    @media (min-width: 777px) {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .field-items a {
		//color: $color-brand-red;
    word-wrap: break-word;
  }

  article.node-blog header {
    @include media-mid {
      padding-right: $gutter;
      width: 140px;
      float: left;
    }
  }

  .field-name-body {
    @include media-mid {
      @include gutter;
      width: calc(100% - 140px);
      float: left;
    }
  }

  .links.inline {
    clear: both;

    @include media-mid {
      margin-left: 140px + $gutter;
    }

		/*a {
      color: $color-brand-red;
		}*/
  }
}

// Blog: Banner Image

.rs-blog__banner {
  img {
    vertical-align: middle;
  }

  + h1 {
    font-size: 100%;
    line-height: 1.2rem;
    background-color: rgba($white, .6);
    padding: $spacing ($spacing * 1.5);
    margin: 0;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    word-wrap: break-word;
    color: $color-steel-black;
    letter-spacing: 0;

    @include media-mid {
      font-size: 2rem;
      line-height: 2.2rem;
    }
  }
}

.rs-blog__banner-wrapper {
  position: relative;
}

// Blog: Byline

.rs-blog__submitted {
  color: $grey-cement;
  font-family: $font-family-1;
  font-weight: 200;
  font-size: $font-lg;
}

.rs-blog__author {
  word-wrap: break-word;
}

.rs-blog__by {
  font-family: $font-family-1;
  font-weight: 200;
  font-size: $font-lg;
  color: $grey-cement;

  span {
    color: $color-brand-red;
    font-weight: 700;

    &.rs-blog__honorific {
      color: $color-steel-black;
      font-weight: 400;
      display: block;
    }
  }

  a {
    text-decoration: none;
  }
}

// Blog: Social Media Section

#block-minimal-share-minimal-share-sticky-wrapper {
  @include media-mid {
    padding-left: $gutter;
    float: left;
  }
}

.block-minimal-share {
  width: 165px !important;
  position: unset !important;
  margin: 0 auto;
  @include media-mid {
    width: 75px !important;
    margin: 0;
    position: fixed !important;
  }

  .block__title {
    color: $white;
    background-color: $color-brand-red--mid;
    text-transform: uppercase;
    line-height: 1.2;
    font-size: $font-sm-x;
    margin: 0;
    padding: ($spacing - 2.5);
    text-align: center;
  }
}

.minimal-share {
  background-color: $grey-titanium;
  padding: 20px;
  height: 75px;
  @include media-mid {
    height: auto;
  }

  a:last-of-type {
    margin: 0;
  }

  a {
    border-radius: 5%;
    width: 35px;
    margin-right: 5px;
    float: left;
    @include media-mid {
      float: none;
    }
  }

  a.icon {
    font-family: icomoon;
  }

  a.icon.facebook::before {
    content: '\e907';
  }

  a.icon.twitter::before {
    content: '\f099';
  }

  a.icon.gplus::before {
    content: '\ea8b';
  }
}

.minimal-share > * {
  margin: 0 0 $spacing;
  display: block;
}

.links .blog_usernames_blog {
  display: none !important;
}

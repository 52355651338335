// Page: Condition
//
// Condition page styles
//
// Markup: condition.twig
//
// Style guide: rosept.condition

.taxonomy-term.vocabulary-medical-conditions > h2 {
  display: none;
}

.field-name-field-image img {
  width: auto;
}

// Page: Condition: Banner

.rs-header--wrapper {
  position: relative;
  min-height: 120px;

  img {
    vertical-align: middle;
  }

  .rs-title {
    background-color: rgba($white, .6);
    font-size: 1.9rem;
    line-height: 1.2;
    padding: $spacing ($spacing * 1.5);
    margin: 0;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    color: $color-steel-black;
    hyphens: auto;

    @include media-mid {
      font-size: 2.2rem;
    }
  }
}

#taxonomy-term-22 {

  .taxonomy-term-description {
    font-family: $font-family-2;
    font-size: $font-lg;
    margin: $spacing 0 0;
  }
}

// Page: Condition: Listings

.view-id-taxonomy_term {
  .node-blog {
    margin-bottom: ($spacing * 2.5);

    @include media-mid {
      @include row($flex: true);
    }

    .group-left {
      @include media-mid {
        @include col;
        flex: 1;
      }
    }

    .group-right {
      @include media-mid {
        @include col;
        flex: 2;
      }
    }

    .more-link {
      font-weight: $font-weight-bold;
      font-family: $font-family-1;
      text-decoration: none;
      padding: 0 0 0 $spacing-half;

      &:after {
        content: '>';
        padding: 0 0 0 $spacing-half;
      }
    }
  }

  @include media-mid {
    @include col(66.66%);
    padding-left: 0;
  }
}

// ends view-id-taxonomy_term

.view-taxonomy-term-fully-rendered {
  padding-bottom: ($spacing * 1.5);
}

// Page: Condition: Related

#block-views-51d05e753334a7feb28f343128403d94 {
  margin: $spacing-3 0 0;

  .block__title {
    font-size: 100%;
    text-transform: uppercase;
    line-height: 1;
    margin-bottom: $spacing;
  }

  .view-related-treatments-services {
    background-color: $grey-mist;
    padding: ($spacing * 1.5);

    .views-row {
      font-family: $font-family-1;
      font-size: $font-lg;
      padding: 0 0 $spacing-half;

      a {
        font-weight: $font-weight-bold;
        text-decoration: none;
      }
    }
  }

  @include media-mid {
    @include col(33.33%, $float: right);
    padding-right: 0;
    margin-top: 0;
  }
}

// ends #block-views-51d05e753334a7feb28f343128403d94

// Headings
//
// All HTML headings, `<h1>` through `<h6>`, are available. `%h1` through `%h6`
// classes are also available, for when you want to match the font styling of a
// heading but still want your text to be displayed inline.
//
// Markup: headings-h.twig
//
// Style guide: base.headings

// Address variable `h1` font-size and margin within `section` and `article`
// contexts in Firefox 4+, Safari, and Chrome.

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family;
  font-weight: normal;
}

h1,
%h1 {
  // Set the font-size and line-height while keeping a proper vertical rhythm.
  @include type-layout(xxl, 2.5);

  // Set 1 unit of vertical rhythm on the top and bottom margins.
  @include margin-top(2, xxl);
  @include margin-bottom(2, xxl);
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 2px;
  color: $color-brand-red;
}

h2,
%h2 {
  @include type-layout(xl, 1.5);
  @include margin-top(1, xl);
  @include margin-bottom(1, xl);
  font-weight: 500;
}

h3,
%h3 {
  @include type-layout(l, 1.5);
  @include margin-top(1, l);
  @include margin-bottom(1, l);
}

h4,
%h4 {
  @include type-layout(m, 1);
  @include margin-top(1, m);
  @include margin-bottom(1, m);
}

h5,
%h5 {
  @include type-layout(s, 1);
  @include margin-top(1, s);
  @include margin-bottom(1, s);
}

h6,
%h6 {
  @include type-layout(xs, 1);
  @include margin-top(1, xs);
  @include margin-bottom(1, xs);
}

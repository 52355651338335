// Page: Why Rose
//
// Why Rose page styles
//
// Markup: why_rose.twig
//
// Style guide: rosept.why_rose

body.page-node-3 {
  main > h1 {
    display: none;
  }

  footer {
    margin-top: 0;
  }
}

.why-rose__video-title-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: $spacing-3;

  .why-rose__video-wrapper {
    flex: 1 1 560px;

    .media_embed {
      width: 100%;
      max-width: 100%;
    }
  }
}

.why-rose__title-wrapper {
  margin-right: 2rem;
  flex: 1 1 250px;

  .why-rose__title {
    margin-bottom: 0;
    white-space: nowrap;
    font-size: 2.5rem;
    letter-spacing: 1;
  }

  .why-rose__subtitle {
    line-height: 1.2;
    font-size: 1.75rem;
    font-weight: 400;
  }
}

.rs-bullets__wrapper-all__outer {
  width: 100vw;
  height: 100%;
  margin-left: calc(50% - 50vw);
  background-color: $grey-mist;
  padding-top: $spacing-4;
  @extend %clearfix;
}

.rs-bullets__wrapper-all__inner {
  max-width: $site-max-width;
  margin: auto;
  position: relative;
  @extend %clearfix;
}

.media_embed {
  @extend %responsive-video;

  iframe {
    @extend %responsive-video__embed;
  }

  @include media-lg {
    max-width: 75%;
    margin: 0 auto;
  }
}

.section-we-set-bar-great-physical-therapy .field-collection-container {
  @extend %clearfix;
}

.rs-bullets__wrapper {
  min-height: 182px;
  margin: 0 auto;

  @include media-lg {
    width: 360px;
    height: 200px;
    padding-left: 0;
    float: left;
    clear: both;
    background-image: none;
  }
}

.rs-bullets__wrapper-outer {
  width: 100%;
  height: 100%;
  @extend %clearfix;
  position: relative;
  padding: 0 $spacing;

  @include media-lg {
    padding: 0;
  }

  .rs-bullets__image .colorbox-load { // Play button.
    // @TODO convert to .rs-little-play. Future devs see videos.s
    transform: translate(-50%, -50%);
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;

    &:after {
      padding: 5px 10px;
      font-size: 13px;
      color: $white;
      background-color: $color-brand-red;
    }

    &:hover:after {
      color: $color-steel-black;
      background-color: $white;
      border: $grey-cement 1px solid;
    }

    &.rs-bullets__title-hover:after {
      color: $color-steel-black;
      background-color: $white;
      border: $grey-cement 1px solid;
    }
  }

  &:nth-of-type(odd) { // Odd, float right on desktop
    .rs-bullets__wrapper {
      float: left;
    }

    .rs-bullets__image:after { // Line
      @include media-lg {
        transform: rotate(-15deg);
        right: 2px;
        left: auto;
        top: 109px;
      }
    }

    .rs-bullets__image {
      left: calc(50% - 20px);

      .colorbox-load { // Play button
        right: -2px;
        left: auto;

        @include media-lg {
          right: auto;
          left: 0;
        }
      }
    }
  }

  &:nth-of-type(even) { // Even, float right on desktop.
    .rs-bullets__wrapper {
      float: left;

      @include media-lg {
        float: right;
      }
    }

    .rs-bullets__image:after { // Line
      @include media-lg {
        left: 26px;
        top: 117px;
        right: auto;
        transform: rotate(15deg);
      }
    }

    .rs-bullets__image {
      left: calc(50% + 20px);

      .colorbox-load {
        right: -2px;
        left: auto;
      }
    }
  }
}

.rs-bullets__wrapper-all__inner section:last-of-type .rs-bullets__image:after {
  display: none;
}

// ends rs-bullets__wrapper

.rs-bullets__image {
  background-position: center;
  background-repeat: no-repeat;
  float: left;
  margin-right: $spacing + $spacing-half;
  width: 80px;
  height: 80px;

  @include media-mid {
    height: 100px;
    width: 100px;
    margin-right: $spacing-2;
  }

  @include media-lg {
    transform: translate(-50%, -50%);
    display: block;
    position: absolute;
    top: 60px;
    margin: 0;
    width: 115px;
    height: 115px;
  }

  img {
    border-radius: 50%;
  }

  &:after {
    @include media-mid {
      content: '';
      background: url('/sites/all/themes/rose_new/images/why-rose-line.svg') top left no-repeat;
      width: 30px;
      height: 150px;
      position: absolute;
      display: block;
      left: calc((100px + #{$spacing-2}) / 2);
      top: 101px;
    }

    @include media-lg {
      left: calc((151px / 2) - #{$spacing-half});
      z-index: -2;
      top: 116px;
    }
  }
}

.rs-bullets__image-inner {
  height: 100%;
  width: 100%;
  position: relative;
}

.rs-bullets__title {
  font-family: $font-family-1;
  font-size: 120%;
  font-weight: $font-weight-bold;
  line-height: 1.2;
  color: $color-brand-red;
  margin-bottom: $spacing;
  hyphens: auto;
  float: left;
  display: table;
  height: 80px;
  width: calc(100% - 80px - #{$spacing + $spacing-half});
  text-align: left;

  @include media-mid {
    font-size: 160%;
    width: calc(100% - 115px - #{$spacing-2});
  }

  @include media-lg {
    float: none;
    height: auto;
    display: block;
    width: auto;
  }

  a, .rs-bullets__title-unlinked {
    display: table-cell;
    vertical-align: middle;
    &:hover {
      color: $color-steel-black;
    }
  }
} // ends rs-bullets__title

.rs-bullets__blurb {
  line-height: 1.6;
  padding: 0 0 $spacing-2;
  text-align: justify;
  hyphens: auto;
  float: left;
  margin-top: $spacing-half;
  margin-bottom: $spacing;

  @include media-mid {
    width: calc(100% - 115px - #{$spacing-2});
    margin-top: 0;
    margin-bottom: 0;
  }

  @include media-lg {
    width: auto;
    padding: 0;
    float: none;
    height: 400px;
  }

  a {
    color: inherit;
  }

  a:hover {
    color: $color-brand-red--dark;
  }
}

.rs-bullets__title, .rs-bullets__blurb {

  .colorbox-load {

    &:after {
      content: '';
      background-color: transparent;
    }

    &:hover:after {
      background-color: transparent;
    }
  }
}

article.node-page {
  .action-links-field-collection-add,
  .field-collection-description {
    display: none;
  }
}


// Helpers
//
// Alignment, Vertical Spacing, Inline Block
//
// Markup: helpers.twig
//
// Style guide: rosept.helpers

// Clearing

@mixin clearfix {
  &:after {
    content: '';
    display: table;
    clear: both;
  }
}

.clearfix, %clearfix {
  @include clearfix;
}

// Alignment

.center {
  text-align: center;
}

@mixin vertical-center {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.vertical-center, %vertical-center {
  @include vertical-center;
}

@mixin dead-center {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.dead-center, %dead-center {
  @include dead-center;
}

.inline-block {
  display: inline-block;
}

.inline-block-top {
  display: inline-block;
  vertical-align: top;
}

// Full-width: Use for full-width images, videos, form elements, etc.

.full-width, %full-width {
  width: 100%;
  display: block;
}

// Full-width-window: Use for full-width blocks, spanning the width of the window
// Hack to break blocks out of site wrapper max-width
// Requires overflow-x hidden on body

.full-width-window, %full-width-window {
  padding-left: 600rem;
  padding-right: 600rem;
  margin-left: -600rem;
  margin-right: -600rem;
}

// Vertical Spacing

// Outputs classes & placeholders from variables file such as:
// spacing-v, spacing-2-v, spacing-3-v, spacing-4-v, spacing-half

$spacing-v-site: (
        spacing: $spacing,
        spacing-2: $spacing-2,
        spacing-3: $spacing-3,
        spacing-4: $spacing-4,
        spacing-half: $spacing-half
);

@each $name, $value in $spacing-v-site {
  .#{$name}-v, %#{$name}-v {
    padding-top: $value;
    padding-bottom: $value;
  }
}




// Icons
//
// Icon font documentation 
//
// To update icons, use the selection.json file inside the font-icons folder inside themes. Then increment $font-icon to uncache.
//
// You can then import the icons via this file to https://icomoon.io/app/ 
//
// Markup: icons.twig
//
// Style guide: rosept.icons

// Icon Spacing

.icon-left, %icon-left {
  margin-right: $spacing;
}

.icon-left-lg, %icon-left-lg {
  margin-right: $spacing-2;
}

.icon-right, %icon-left-lg {
  margin-left: $spacing;
}

.icon-right-lg, %icon-right-lg {
  margin-left: $spacing-2;
}

// Icomoon font icons
@font-face {
  font-family: $font-icon;
  src: url('../font-icons/fonts/icomoon.eot?ly6dff');
  src: url('../font-icons/fonts/icomoon.eot?ly6dff#iefix') format('embedded-opentype'),
  url('../font-icons/fonts/icomoon.ttf?ly6dff') format('truetype'),
  url('../font-icons/fonts/icomoon.woff?ly6dff') format('woff'),
  url('../font-icons/fonts/icomoon.svg?ly6dff#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^='icon-'], [class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: $font-icon !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-certificate-icomoon:before {
  content: '\e900';
}

.icon-play:before {
  content: '\f04b';
}

.icon-twitter:before {
  content: '\f099';
}

.icon-youtube-square:before {
  content: '\f166';
}

.icon-smiley:before {
  content: '\e905';
}

.icon-insurance-card:before {
  content: '\e906';
}

.icon-facebook-icon:before {
  content: '\e907';
}

.icon-dotted-line:before {
  content: '\e908';
}

.icon-dc-location:before {
  content: '\e909';
}

.icon-clock:before {
  content: '\e90a';
}

.icon-clipboard:before {
  content: '\e90b';
}

.icon-appt-calendar:before {
  content: '\e911';
}

.icon-cart:before {
  content: '\e93a';
}

.icon-instagram:before {
  content: '\ea92';
}

// Buttons
//
// Buttons: Brand, Common, Links, Submit, Button Elements
//
// Markup: buttons.twig
//
// Style guide: rosept.buttons

%button-base {
  font-family: $font-family-1;
  text-align: center;
  text-decoration: none;
  outline: none;
  padding: .3em .6em;
  display: inline-block;
  cursor: pointer;
}

@mixin button($bg-color: $color-brand-red, $color: $white, $border-color: $bg-color) {
  @extend %button-base;
  color: $color;
  background-color: $bg-color;
  border: 1px solid $border-color;
  font-weight: 500;
  text-transform: uppercase;

  &:hover, &:focus {
    color: $color;
    background-color: lighten($bg-color, 10%);
  }
}

// Small buttons: Mixin and modifier class
@mixin button--sm {
  font-size: $font-sm;
  padding: .2em .6em;
}

.button--sm {
  @include button--sm;
}

// Large buttons: Mixin and modifier class

@mixin button--lg {
  font-size: $font-lg-x;
  padding: .5em .8em;
}

.button--lg {
  @include button--lg;
}

// Extra Large buttons: Mixin and modifier class

@mixin button--lg-x {
  font-size: $font-lg-xx;
  padding: .6em 1em;
}

.button--lg-x {
  @include button--lg-x;
}

@mixin button--lg-xx {
  font-size: 140%;
  padding: 1em;
}

.button--lg-xx {
  @include button--lg-xx;
}

// Button: Default: Red

button, .button {
  @include button;
}

// Button: White
// (modifier class: add to button class)

.button--white {
  @include button($bg-color: $white, $color: $color-brand-red, $border-color: $color-brand-red);

  &:hover, &:focus {
    color: $color-brand-red--dark;
  }
}

// Button: Green
// (modifier class: add to button class)

.button--green {
  @include button($bg-color: $color-brand-green);
}


// View

.page-physical-therapists h1 {
  margin-bottom: 1rem;
}

#edit-submit-team-members {
  display: none;
}

label[for="edit-field-location-target-id-entityreference-filter"] {
  display: none;
}


.node-teaser {
  .rs-team__honorific {
    text-decoration: none;
    font-weight: 400;
    display: block;
    font-size: .7em;
  }

  a.rs-team__title-wrapper {
    text-decoration: none;
  }

  a.rs-team__title-wrapper,
  .rs-team__header {
    width: 100%;
    height: 100%;
    display: block;
  }

  .rs-team__title {
    color: $color-brand-red;
  }

  .rs-team__honorific-sep {
    display: none;
  }

  &.node-team-member a {
    color: $black;
  }
}

.rs-team__article {
  position: relative;
  padding-bottom: 20px;
  height: auto;
  background-repeat: no-repeat;
  background-size: contain;
}

.rs-team__list-size-rs-220x220 {
  @include media-sm {
    width: #{$ms--full-percent-width-220}#{'%'};
    padding-top: #{$ms--full-percent-width-220}#{'%'};
  }
  width: #{$ms--percent-width-220}#{'%'};
  padding-top: #{$ms--percent-width-220}#{'%'};
}

.rs-team__list-size-rs-460x220 {
  @include media-sm {
    width: #{$ms--full-percent-width-460}#{'%'};
    padding-top: #{$ms--full-percent-width-220}#{'%'};
  }
  width: #{$ms--percent-width-460}#{'%'};
  padding-top: #{$ms--percent-width-220}#{'%'};
}

.rs-team__list-size-rs-220x460 {
  @include media-sm {
    width: #{$ms--full-percent-width-220}#{'%'};
    padding-top: #{$ms--full-percent-width-460}#{'%'};
  }
  width: #{$ms--percent-width-220}#{'%'};
  padding-top: #{$ms--percent-width-460}#{'%'};
}

.view-team-members {
  width: 100%;
  max-width: #{$ms--full-px-width}px;

  .rs-team__header {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    margin: .5rem;

    h2 {
      font-size: 1.25rem;
      line-height: 1.65rem;
      margin: 0;
    }
  }

  ul.inline {
    position: absolute;
    bottom: 0;

    .node-readmore {
      display: none;
    }
  }

  .view-header,
  .view-filters {
    float: left;
  }

  .view-content {
    width: 100%;
    float: left;
  }

  .views-exposed-form {
    .views-exposed-widget .form-submit {
      margin-top: 1.1em;
    }

    .views-submit-button {
      font-size: 100%;
      padding: 10px 15px;
      line-height: 100%;
    }

    select {
      font-size: 100%;
    }
  }

  .view-header {
    padding: 10px 15px;
  }

  .rs-team__filters-only-therapists {
    @include button;
    font-size: 100%;
    line-height: 100%;
    margin-top: 1.1em;
    font-weight: bold;
  }
}

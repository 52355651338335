// Page: Contact
//
// Contact page styles 
//
// Markup: contact.twig
//
// Style guide: rosept.contact

#webform-client-form-6 {
  @include media-mid {
    @include col(50%);
    padding-left: 0;
  }

  .form-item:first-of-type {
    margin-top: 0;
  }

  .form-actions {
    overflow: hidden;
  }

  input[type='submit'] {
    float: right;
  }
}

#webform-client-form-6 + .field-name-field-image-multi, .rs-form__webform-region {

  @include media-mid {
    @include col(50%, $float: right);
    padding-right: 0;
  }
}

.rs-form__webform-region {
  clear: both;
  margin-bottom: 20px;
}

#webform-client-form-6 + .field-name-field-image-multi {
  margin: $spacing 0 0;

  @include media-mid {
    margin: 0;
  }
}

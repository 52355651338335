// 220 relative to 960
//Masonry = ms
$ms--full-px-width: 960;
$ms--full-percent-width-220: (220 / $ms--full-px-width) * 100;
$ms--full-percent-width-460: (460 / $ms--full-px-width) * 100;

$ms--percent-width-220: $ms--full-percent-width-220 * 2;
$ms--percent-width-460: $ms--full-percent-width-460 * 2;

// !!MEDIA QUERIES!!
$media-sm: 480px;
$media-mid: 730px;
$media-mid-lg: 850px; // Rarer.
$media-lg: 985px;
$media-xl: 1280px;

// !!GRID!!
$gutter: 10px;
$site-max-width: 965px;

// Fonts: Weights

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

// Fonts: Sizes

$font-lg-xx: 130%;
$font-lg-x: 120%;
$font-lg: 110%;
$font-sm: 90%;
$font-sm-x: 80%;
$font-sm-xx: 70%;

// !!ICON FONT!!

$font-icon: 'icomoon3';

// !!SPACING!!

$spacing: 1rem;
$spacing-2: ($spacing * 2);
$spacing-3: ($spacing * 3);
$spacing-4: ($spacing * 4);
$spacing-5: ($spacing * 5);
$spacing-half: ($spacing / 2);

// !!COLORS!!

// Colors: Brand
$color-brand-red: #dd3a31;
$color-brand-red--dark: #a50b01;
$color-brand-red--mid: #e63a2e;
$color-brand-red--light: #fbb49e;

$color-brand-green: #0c8400;
$color-brand-blue: #0092f7;
$color-brand-blue--light: #aedcfe;

// Colors: Link

$color-link: $color-brand-red;
$color-link-hover: $color-brand-red--dark;

// Colors: Black through White
$black: #000;
$gray-almost-white: #f4f4f4;
$grey-whitish: #f0f0f0;
$grey-mist: #f3f3f4;
$grey-cloud: #ebebeb;
$grey-titanium: #dfdfdf;
$grey-cement: #999;
$white: #fff;

// Colors: Font
$color-font-base: $black;

// Colors: New
$color-steel-black: #1f2131;
$color-light-salmon: #fdece4;
$color-text-gray: #55565a;
$color-brand-blue-darker: #4289c8;
$color-athens-gray: #e8e8ea;

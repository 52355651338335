// Page: Location
//
// Location page styles
//
// Markup: location.twig
//
// Style guide: rosept.location

body.page-locations {
  h1 {
    margin-bottom: ($spacing + $spacing-half);
  }
}

.view-id-locations {
  font-family: $font-family-1;
  margin: 0 0 $spacing-4;

  .views-field-title-1 a {
    height: 1px;
    color: white;
    font-size: 1px;
  }
}

// Location: Tabs top

ul.quicktabs-tabs {
  font-weight: 500;
  padding: 0;
  text-transform: uppercase;
  font-size: .9rem;
  letter-spacing: 0;

  @include media-mid {
    font-size: 1.1rem;
    letter-spacing: 1px;
    border-bottom: 3px solid $color-brand-red;
  }

  li {
    background: $color-light-salmon;
    padding: $spacing-half $spacing;
    display: inline-block;
    margin-right: -5px; // reset inline-block whitespace
    letter-spacing: 1px;
    width: 100%;
    margin-bottom: .5rem;

    @include media-mid {
      padding: $spacing-half $spacing-2;
      width: auto;
      margin-bottom: 0;
    }

		&:hover {
			background-color: $color-brand-red;
      a {
        color: $white;
      }
		}

    &.active {
      background: $color-brand-red;

      a {
        color: $white;
      }
    }
  }

  a {
    color: inherit;
    text-decoration: none;
  }
}

.node-location.view-mode-full {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @include media-lg {
    flex-wrap: nowrap;
  }
}

.rs-location__header {
  flex: 1 1 200px;
  margin-bottom: $spacing-3;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @include media-lg {
    display: block;
    margin-right: $spacing-3;
  }
}

.field-name-field-image-map {
  flex: 1 1 auto;

  .field-items picture:not(:last-of-type) {
    margin-bottom: $spacing-4;
    display: block;
  }
}

.rs-location__address-phone-wrapper {
  flex: 1 1 33%;
  text-align: center;
  min-width: 200px;

  @include media-lg {
    text-align: left;
  }
}

.rs-location__maps-video-wrapper {
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 66%;
  justify-content: space-around;

  @include media-lg {
    width: 150px;
    display: block;
  }
}

.country {
  display: none;
}

.organisation-name {
  font-weight: 700;
  margin-bottom: $spacing-half;
  color: $color-brand-red;
  display: block;
}

.field-name-field-address {
  .locality-block {
    display: block;
  }

  .street-block {
  }
}

.field-name-field-phone {
  margin-bottom: $spacing;
}

.rs-location__gmap-link {
  margin-bottom: $spacing-2;
  order: 2;
  margin-top: 107px;

  @include media-lg {
    margin-top: 0;
  }

  a {
    @include button($bg-color: $color-steel-black, $color: $white);
    width: 100%;
    border: 2px solid transparent;

    &:hover {
      color: #fff !important;
      border: 2px solid $color-brand-red;
      background-color: #333650;
    }
  }
}

.rs-location__getting-there {
  order: 1;
}

.field-name-field-getting-there-video {
  .field-item {
    text-align: center;
  }
}

.rs-getting-there__image {
  display: inline-block;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;
  width: 85px;
  height: 85px;
  margin-bottom: 1rem;
}

.rs-getting-there__button {
  a {
    @include button($color-steel-black, $white);
    width: 100%;
    border: 2px solid transparent;

    &:after {
      display: none;
    }

    &:hover {
      color: #fff !important;
      border: 2px solid $color-brand-red;
      background-color: #333650;
    }
  }
}

// Forms
//
// Form styles for inputs, textareas, selects
//
// Markup: forms.twig
//
// Style guide: rosept.forms

// Inputs: Text and Selects

// Labels

label {
  font-family: $font-family-1;
}

.input-text, select, input[type='text'], input[type='email'], input[type='tel'], input[type='number'], textarea {
  font-family: $font-family-1;
  font-size: $font-lg-xx;
  border: none;
  background-color: $color-light-salmon;
  padding: $spacing ($spacing * 1.5);
  width: 100%;
  ::placeholder {
    color: $color-steel-black;
    font-family: $font-family-1;
    font-size: 90%;
  }

  &:focus {
    border: 1px solid $color-brand-red;
    outline: 0;
  }
}

html select {
  padding: 1rem 5rem 1rem 1rem;
  line-height: 1;
  border-radius: 0;
  appearance: none;
  overflow: hidden;
  background: $color-light-salmon url(../images/arrow-dropdown.svg) no-repeat;
  background-size: 1.5rem;
  background-position: calc(100% - 1rem) 50%;

  &:hover {
    cursor: pointer;
  }
}

.view-filters {
  margin-bottom: 2rem;
}

// Form Field Wrap: Use for padding between form elements

.form-field-wrap, .form-item {
  margin: .7rem 0;
}

// Submit inputs and buttons

button, input[type='submit'] {
  @include button;
  @include button--lg-x;
  font-weight: $font-weight-bold;
  padding: .6em 1.6em;
}

// Page: Team Member
//
// Team member landing page.
//
// Markup: team-member.twig
//
// Style guide: rosept.team-member

// Team Member desc and image
// Title
.rs-pt__element {
  margin-bottom: $spacing-2;
}

.node-team-member.view-mode-full {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @include media-mid {
    flex-wrap: nowrap;
  }

  .rs-pt__pos {
    flex: 1 1 400px;

    &-left {
      @include media-mid {
        margin-right: $spacing-2;
      }
    }
  }

  ul.links {
    position: absolute;
    z-index: 1;
    right: 0;
    top: -3rem;
  }
}

.title__team-member {
  letter-spacing: 0;
  text-transform: none;
  line-height: 1.4;

  .rs-team__title {
    font-size: 2.5rem;
    font-weight: 500;
    margin-bottom: 1rem;
    line-height: 1.4;
  }

  .rs-team__honorific {
    display: block;
    color: $color-steel-black;
    font-size: 1.8rem;
  }
}

.rs-pt__element-pic {
  img {
    width: 100%;
  }
}

// Location
.node-team-member .rs-therapist__location-button {
  @include button($color-brand-blue-darker);
  margin-bottom: 1rem;
  font-size: 1rem;

  a {
    color: white;
  }
}

.rs-pt__element-location {
  background-color: $color-light-salmon;
  padding: $spacing-2 ($spacing  * 1.5) $spacing ($spacing * 1.5);
  font-size: 1.1rem;
}

.field-name-field-location {
  .field-label {
    font-weight: 500;
    font-family: $font-family-1;
    font-size: 100%;
    text-transform: uppercase;
    line-height: 1;
    margin-bottom: ($spacing  * 1.5);
  }
}

// Credentials
.rs-pt__element-credential {
  background-color: $color-light-salmon;
  padding: $spacing-2 ($spacing  * 1.5) $spacing ($spacing * 1.5);

  .field-items .field-item { // Overriding css.
    margin-bottom: $spacing;
  }

  .field-collection-view {
    margin: 0;
    padding: 0;
  }

  .field-name-field-image {
    float: left;
    margin-right: $spacing-2;

    img {
      width: 85px;
    }
  }

  .field-name-field-text-multi {
    float: left;
    max-width: 300px;
    font-size: 1.1rem;
    line-height: 1.6;
  }
}

.rs-pt__pos-bottom {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;

  .rs-pt__element-pic-rs_220x150 {
    @include media-mid {
      margin-right: $spacing-2;
    }
  }

  .rs-pt__element-pic-rs_220x150,
  .rs-pt__element-hiring {
    flex: 1 1 210px;
    margin-bottom: $spacing-2;
    display: flex; // For eq heights
    align-items: stretch;
  }

  .rs-pt__element-hiring {
    width: 100%;

    .block {
      width: 100%;
      background-color: $color-brand-red;
    }
  }
}

// Hiring
.rs-team__hiring {
  text-align: center;
  font-family: $font-family-1;
  font-size: $font-sm;
  line-height: 1.4;
  color: $white;
  background-color: $color-brand-red--mid;
  padding: $spacing;
  font-weight: 300;
  width: 100%;

  a {
    color: currentColor;
    text-decoration: none;
    @include button($color-steel-black, $white, $white);
    margin-top: $spacing-half;
  }

  @include media-sm {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  // to get equal height red blurb box
}

.rs-team__hiring-exclam {
  font-family: $font-family-2;
  font-size: 140%;
  font-weight: 800;
  font-style: italic;
  white-space: nowrap;

  @include media-mid {
    font-size: 200%;
  }
}

.rs-team__hiring-blurb {
  @include media-sm {
    display: flex;
  }
}

// Team Member: Listing

.view-team-member-blogs {
  .view-content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @include media-mid {
      flex-wrap: nowrap;
    }

    .views-row {
      flex: 1 1 400px;
    }

    > div:not(:last-of-type) {
      @include media-mid {
        margin-right: $spacing-2;
      }
    }


    .rs-left { // Wrapping the image
      margin-bottom: $spacing;
    }
  }

  .views-field-title {
    font-weight: 500;
    font-family: $font-family-1;
    font-size: 1.3rem;
    text-transform: uppercase;
    line-height: 1;
    margin-bottom: ($spacing  * 1.5);
  }

  .node-teaser {
    margin-bottom: ($spacing * 2.5);
  }
}
// ends view-team-member-blogs
